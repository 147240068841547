import { Button, Card, CardContent, Paper, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import VerifiedIcon from "@mui/icons-material/Verified";
import Grid from '@mui/material/Grid2';
import deletePlayer from "./deletePlayer";

function SingleWaiter({ userData, managerMode, devPort, refresh, setRefresh }) {
  function moveOffWaitlist(
    userId,
    playerFirstName,
    playerLastName,
    registerClubId
  ) {
    const editPlayer =
      devPort +
      "edit_player/" +
      userId +
      "/" +
      playerFirstName +
      "/" +
      playerLastName +
      "/-2/-2/" +
      registerClubId +
      "/false/0";
    console.log(editPlayer);
    fetch(editPlayer)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("OFF WAITLIST");
        console.log(data);
        setRefresh(!refresh);
      });
  }

  return (
    <Card>
      <CardContent>
        <Grid container size={{xs:12}}>
          <Grid size={{xs:6}}>{userData.label}</Grid>
          {managerMode === -1 && (
            <Grid size={{xs:3}}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  console.log("Accept");
                  moveOffWaitlist(
                    userData.value,
                    userData.firstName,
                    userData.lastName,
                    userData.clubId
                  );
                }}
              >
                <VerifiedIcon />{" "}
              </Button>
            </Grid>
          )}
          {managerMode === -1 && (
            <Grid size={{xs:3}}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  console.log("DELETE");
                  const fetch_delete =
                    devPort +
                    "/delete_player/" +
                    userData.value +
                    "/" +
                    userData.clubId;
                  deletePlayer(fetch_delete, () => {
                    console.log("Request has been rejected");
                    setRefresh(!refresh);
                  });
                }}
              >
                <DeleteIcon />{" "}
              </Button>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
function ClubWaiterCard({ devPort, clubData }) {
  console.log("MANAGERIAL");
  const [userList, setUserList] = useState();
  const [refresh, setRefresh] = useState(false);
  const getWaitlist = useCallback((clubId) => {
    const fetch_waitlist = devPort + "player_waitlist/" + clubId;
    fetch(fetch_waitlist)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("WAITLIST");
        console.log(data);
        const newData = data.map((info) => {
          return {
            label: info[0] + " " + info[1],
            value: info[2],
            firstName: info[0],
            lastName: info[1],
            clubId: clubData.value,
          };
        });
        setUserList(newData);
      });
  }, [devPort, clubData]);
  useEffect(() => {
    getWaitlist(clubData.value);
  }, [clubData, refresh, getWaitlist]);
  if (!userList || userList.length === 0) {
    return;
  }
  return (
    <Paper elevation={12}>
      <Card>
        <CardContent>
          <RenderWaitlist
            devPort={devPort}
            userList={userList}
            headerLine={" for Club " + clubData.label}
            managerMode={-1}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        </CardContent>
      </Card>
    </Paper>
  );
}
function RenderWaitlist({
  devPort,
  headerLine,
  userList,
  managerMode,
  refresh,
  setRefresh,
}) {
  return (
    <Stack>
      <h5>Pending Requests {headerLine}</h5>
      {userList.map((waiter) => {
        return (
          <SingleWaiter
            devPort={devPort}
            userData={waiter}
            managerMode={managerMode}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        );
      })}
    </Stack>
  );
}
function UserWaitlist({ devPort, userList, managerMode }) {
  console.log("WAITLIST COMPO");
  console.log(userList);
  console.log(managerMode);
  if (!userList || userList.length === 0) {
    return;
  }
  if (managerMode === -1) {
    return userList.map((club) => {
      return (
        <ClubWaiterCard devPort={devPort} clubData={club} key={club.value} />
      );
    });
  }
  return <RenderWaitlist userList={userList} headerLine="" />;
}

export default UserWaitlist;

import { Card, CardContent } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import Layout from "../components/layout/Layout";
import SnackError from "../components/Signups/SnackError";
import UserContact from "../components/membership/UserContact";
import useIsMobileDisplay from "../components/membership/useIsMobileDisplay";
import Grid from "@mui/material/Grid2";
import { ParallaxProvider } from "react-scroll-parallax";
import BackgroundParallax from "../components/parallax/BackgroundParallax";

function ContactPage({ devPort, setAlertMessageSuccess }) {
  const [alertMessage, setAlertMessage] = useState(false);
  const [isMobileDisplay] = useIsMobileDisplay();
  return (
    <Layout managerMode={-2} devPort={devPort}>
      <Card>
        <CardContent>
          <SnackError
            alterMessage={alertMessage}
            setAlertMessage={setAlertMessage}
            snackMessage={
              "An error has occured while sending your message. Please try again"
            }
          />
          {isMobileDisplay && (
            <Stack spacing={3} textAlign="center">
              <h1> Information Request About Chukkerbook</h1>
              <UserContact
                devPort={devPort}
                setAlertMessage={setAlertMessage}
                setAlertMessageSuccess={setAlertMessageSuccess}
              />
            </Stack>
          )}
          {!isMobileDisplay && (
            <Grid container spacing={2}>
              <Grid size={{ xs: 6 }}>
                <Grid size={{ xs: 8 }} offset={{ xs: 2 }}>
                  <Stack spacing={3} textAlign="center">
                    <h1> Information Request About Chukkerbook</h1>
                    <UserContact
                      devPort={devPort}
                      setAlertMessage={setAlertMessage}
                      setAlertMessageSuccess={setAlertMessageSuccess}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Grid size={{ xs: 6 }}>
                <ParallaxProvider>
                  <BackgroundParallax />
                </ParallaxProvider>
              </Grid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </Layout>
  );
}

export default ContactPage;

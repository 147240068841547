import { Badge, Menu, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import userLogout from "./userLogout";

function SettingsMenu({
  devPort,
  anchorEl,
  setAnchorEl,
  managerMode,
  betaMode,
}) {
  let navigate = useNavigate();
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={() => navigate("/user-settings")}>Settings</MenuItem>
      {(managerMode === -1 || betaMode) && (
        <Badge badgeContent="New" color="success" style={{ right: 15, top: 8 }}>
          <MenuItem
            onClick={() => navigate("/analyticsRevamp")}
            style={{ right: -15, top: -8 }}
          >
            Analytics
          </MenuItem>
        </Badge>
      )}
      {(managerMode === -1 || managerMode === -4 || betaMode) && (
        <MenuItem
          onClick={() => {
            console.log("Redirecting to gitbook");
            window.location.href = "https://chukkerbook.gitbook.io/docs";
          }}
        >
          User Guide
        </MenuItem>
      )}
      <MenuItem
        onClick={() => {
          userLogout(devPort);
          navigate("/account-login");
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
}
export default SettingsMenu;

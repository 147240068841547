import { Alert, Snackbar } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import PlayerList from "../components/PlayerManagement/PlayerList";
import useRefresh from "../components/Refresh/refresh";
import moment from "moment";

function PlayerPage({
  clubId,
  devPort,
  waiverNotifications,
  setWaiverNotifications,
  managerMode,
  betaMode
}) {
  const [players, setPlayers] = useState();
  const [loadingPlayers, setLoadingPlayers] = useState(true);
  const [playersUsage, setPlayersUsage] = useState({})
  useRefresh(clubId, devPort);
  const loadPlayers = useCallback(() => {
    fetch(devPort + "get_club_usage/" + clubId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("LOOK AT THIS USAGE");
        console.log(data);
        if(data && data[0]){
          const newUsage={
            "Total Players": data[0][0],
            "Number Pros": data[0][1],
            "Number Chukkerbook Accounts": data[0][2],
            "Number Active Accounts": data[0][3]
          }
          setPlayersUsage(newUsage);
        }
        console.log(data[0])
      });

    fetch(devPort + "player_list/" + clubId + "/False")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let formattedData = [];
        let duplicate_player = {};
        data.map((player) => {
          if (!duplicate_player[player[4]]) {
            duplicate_player[player[4]] = true;
            let latest_game=null
            if(player[10] && !player[11]){
              latest_game=moment(player[10])
            } else if(!player[10] && player[11]){
              latest_game=moment(player[11])
            } else if(player[10] && player[11]){
              latest_game=moment.max(moment(player[10]), moment(player[11]))
            }
            formattedData.push({
              first_name: player[0],
              last_name: player[1],
              uspa_rating: player[2],
              club_rating: player[3],
              player_id: player[4],
              is_club_pro: player[5],
              has_chukkerbook_account: player[6],
              up_to_date_waiver: player[7],
              is_legacy_system: player[8],
              is_archived: player[9],
              last_signup: latest_game
            });
          }
          return null;
        });
        console.log("HERE I AM");
        console.log(formattedData);
        setPlayers(formattedData);
        setLoadingPlayers(false);
      });
  }, [devPort, clubId]);
  useEffect(() => {
    loadPlayers();
  }, [loadPlayers]);
  function addNewPlayer() {
    loadPlayers();
    setAlertMessage(true);
  }
  function deletePlayer(playerId) {
    fetch(devPort + "/delete_player/" + playerId + "/" + clubId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        loadPlayers();
      });
  }

  function updateArchiveStatus(playerId, isArchived) {
    const archived_data = {
      club_id: clubId,
      player_id: playerId,
      is_archived: isArchived,
    };
    console.log(devPort + "set_archived_status");
    fetch(devPort + "set_archived_status", {
      mode: "cors",
      credentials: "include",
      method: "POST",
      body: JSON.stringify(archived_data),
    }).then((response) => {
      console.log("PROCESSED");
      loadPlayers();
    });
  }

  const [alterMessage, setAlertMessage] = useState(false);
  return (
    <Layout managerMode={managerMode} waiverNotifications={waiverNotifications} devPort={devPort}>
      <div>
        <Snackbar
          open={alterMessage}
          autoHideDuration={3000}
          onClose={() => {
            setAlertMessage(false);
          }}
        >
          <Alert severity="success"> Player has been added to the club!</Alert>
        </Snackbar>
        <PlayerList
          devPort={devPort}
          loadingPage={loadingPlayers}
          managerMode={managerMode}
          title="All Club Members"
          clubId={clubId}
          players={players}
          nameEdit={true}
          ratingEdit={true}
          chukkerEdit={false}
          refresh={addNewPlayer}
          playerDelete={deletePlayer}
          updateArchiveStatus={updateArchiveStatus}
          waiverNotifications={waiverNotifications}
          setWaiverNotifications={setWaiverNotifications}
          tableMode="members"
          playersUsage={playersUsage}
          betaMode={betaMode}
        />
      </div>
    </Layout>
  );
}
export default PlayerPage;

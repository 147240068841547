import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
//import AppAppBar from '../components/MarketingPage/AppAppBar';
import Hero from "../components/MarketingPage/Hero";
//import LogoCollection from "../components/MarketingPage/LogoCollection";
//import Highlights from "../components/MarketingPage/Highlights";
//import Pricing from "../components/MarketingPage/Pricing";
import Features from "../components/MarketingPage/Features";
//import Testimonials from "../components/MarketingPage/Testimonials";
import FAQ from "../components/MarketingPage/FAQ";
//import Footer from "../components/MarketingPage/Footer";
import Layout from "src/components/layout/Layout";
import SnackSuccess from "src/components/Signups/SnackSuccess";
import CookieConsent from "react-cookie-consent";
import { ParallaxProvider } from "react-scroll-parallax";
import { Button, Stack, useTheme } from "@mui/material";
import BackgroundParallax from "src/components/parallax/BackgroundParallax";
//import AppTheme from '../shared-theme/MarketingPage/AppTheme';
import "./pages.css";
import Contact from "src/components/MarketingPage/Contact";
import SnackError from "src/components/Signups/SnackError";

export default function MarketingPage({
  userName,
  setUserName,
  devPort,
  currentMembership,
  alertMessageSuccess,
  setAlertMessageSuccess,
  setBetaMode,
}: {
  userName: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  devPort: string;
  currentMembership: string;
  alertMessageSuccess: boolean;
  setAlertMessageSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setBetaMode: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const theme = useTheme();
  const [alertMessage, setAlertMessage] = React.useState(false);
  return (
    <Layout
      managerMode={userName ? (currentMembership === "manager" ? -4 : -5) : -3}
      devPort={devPort}
    >
      <SnackError
        alterMessage={alertMessage}
        setAlertMessage={setAlertMessage}
        snackMessage={
          "An error has occured while sending your message. Please try again"
        }
      />
      <SnackSuccess
        alterMessage={alertMessageSuccess}
        setAlertMessage={setAlertMessageSuccess}
        snackMessage="Your message has been sent to the Chukkerbook team!"
      />
      <CookieConsent
        location="bottom"
        buttonText="I Understand"
        cookieName="ChukkerBookCookieConsent"
        style={{ background: theme.palette.primary.main }}
        ButtonComponent={Button}
        buttonStyle={{ background: theme.palette.success.main }}
        overlay={true}
        onOverlayClick={() => console.log("Accept cookies first")}
        expires={150}
      >
        This website uses only strictly necessary cookies to keep the user
        logged in during a session.{" "}
      </CookieConsent>
      <ParallaxProvider>
        <Stack spacing={2}>
          <BackgroundParallax />
          {/*<ParallaxReactBanner
            className="image"
            blur={10}
            bgImage={Grass}
            strength={700}
  >*/}
          <CssBaseline enableColorScheme />

          {/*<AppAppBar />*/}
          <Hero />
          <div>
            <Features setBetaMode={setBetaMode} />
            {/*<Divider />
            <LogoCollection />
            <Testimonials />*/}
            <Divider />
            {/*<Highlights />
              <Divider />
<Pricing />
<Divider />*/}
            <FAQ />
            <Divider />
            {/*<Footer />*/}
            <Contact
              devPort={devPort}
              setAlertMessageSuccess={setAlertMessageSuccess}
              setAlertMessage={setAlertMessage}
            />
          </div>
          {/*</ParallaxReactBanner>*/}
        </Stack>
      </ParallaxProvider>
    </Layout>
  );
}

import { TextField } from "@mui/material";

function EmailBox({ setLoginEmail, betaMode }) {
  return (
    <TextField
      id="custom-email"
      data-testid="contact-user-email"
      label="Email"
      disabled={!betaMode}
      style={{ width: "100%" }}
      onChange={(input) => {
        console.log(input.target.value);
        setLoginEmail(input.target.value);
      }}
    />
  );
}

export default EmailBox;

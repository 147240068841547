import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import { useState } from "react";
import sqlTableMapping from "./sqlTableMapping";

function LessonAddPricing({
  devPort,
  clubId,
  priceType,
  refreshPricing,
  setRefreshPricing,
}) {
  const [lessonNickname, setLessonNickname] = useState();
  const [lessonPrice, setLessonPrice] = useState();
  const [currency, setCurrency] = useState("usd");
  function refreshFields() {
    setLessonNickname("");
    setLessonPrice("");
    setCurrency("usd");
  }
  function createPricing() {
    const isFloat = /\d+\.\d+/.test(lessonPrice);
    const isInt = /\d+/.test(lessonPrice);
    const valid_number = isFloat || isInt;
    if (!lessonNickname) {
      window.alert("Enter a name for the " + priceType + " pricing");
    } else {
      if (!lessonPrice || !valid_number) {
        window.alert("Enter a valid price for the " + priceType);
      } else {
        const priceFetch = devPort + "create-lesson-pricing";
        const priceSave = {
          name: lessonNickname,
          price: parseFloat(lessonPrice) * 100,
          currency: currency,
          clubId: clubId,
          tableType: sqlTableMapping[priceType]
        };
        console.log(priceSave);
        fetch(priceFetch, {
          method: "POST",
          body: JSON.stringify(priceSave),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(data);
            //const stripePriceId = data["default_price"];
            refreshFields();
            setRefreshPricing(!refreshPricing);
          });
      }
    }
  }
  return (
    <Paper elevation={24}>
      <Card variant="elevation">
        <CardContent>
          <Stack spacing={2}>
            <h1>Add New Pricing</h1>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
              data-testid="pricing-disclaimer-single"
            >
              Stripe and Chukkerbook charge a total of 3.9% + 55 cents per
              transaction for credit card payments. The fees will be deducted
              from the total price set for the lesson. Please set your pricing
              accordingly.
            </Typography>
            <TextField
              id="add-lesson-nickname"
              label="Add Pricing Nickname"
              data-testid="add-lesson-nickname"
              style={{ width: "100%" }}
              value={lessonNickname}
              onChange={(input) => {
                console.log(input.target.value);
                setLessonNickname(input.target.value);
              }}
            />
            <Grid container size={{xs:12}}>
              <Grid size={{xs:6}}>
                <TextField
                  id="add-lesson-pricing"
                  label={
                    priceType === "Lease"
                      ? "Leasing price per chukker"
                      : priceType + " Price"
                  }
                  data-testid="add-lesson-pricing"
                  style={{ width: "100%" }}
                  value={lessonPrice}
                  onChange={(input) => {
                    setLessonPrice(input.target.value);
                  }}
                />
              </Grid>
              <Grid size={{xs:6}}>
                <FormControl fullWidth>
                  <InputLabel id="pricing-currency-lesson">Currency</InputLabel>
                  <Select
                    labelId="select-currency-lesson"
                    data-testid="select-currency-lesson"
                    value={currency}
                    label="Currency"
                    onChange={(event) => setCurrency(event.target.value)}
                  >
                    <MenuItem value={"usd"}>USD</MenuItem>
                    <MenuItem value={"cad"}>CAD</MenuItem>
                    <MenuItem value={"eur"}>Euros</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              data-testid="register-pricing-lesson"
              onClick={() => {
                //setLessonDelete("with " + lesson.pro + " at " + lesson.time);
                //setLessonToDelete(lesson.id);
                createPricing();
                console.log("HERE");
              }}
              color="success"
              fullWidth={true}
            >
              <RequestQuoteIcon />
              {"Register New Pricing"}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default LessonAddPricing;

import {
  Button,
  Card,
  CardContent,
  Slider,
  TextField,
  Stack,
  Alert,
  Snackbar,
  LinearProgress,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState, useEffect, useCallback } from "react";
import Layout from "../components/layout/Layout";
import RegistrationModal from "../components/PlayerManagement/RegistrationModal";
//import useRefresh from "../components/Refresh/refresh";
import ProBox from "../components/Signups/ProBox";
import DashboardTabs from "../components/ui/DashboardTabs";
import { useNavigate, useSearchParams } from "react-router-dom";
import currencyFormatter from "../components/Lessons/currencyFormatter";
import PlayerBox from "../components/Signups/PlayerBox";
import Grid from '@mui/material/Grid2';
import BannerMessage from "../components/Signups/PriceBanner";
import PurchasePackage from "../components/Lessons/PurchasePackage";
import creditsRetriever from "../components/Lessons/creditsRetriever";
import CreditsBanner from "../components/Lessons/CreditsBanner";

// Adds a player's signup for a game
function AddPlayerPage({
  gameId,
  clubId,
  gameDate,
  devPort,
  waiverNotifications,
  managerMode,
  leasePrice,
  setLeasePrice,
  setManagerMode,
  setClubId,
  setGameId,
  availableHorseForLease,
}) {
  const [chukkerRequested, setChukkerRequested] = useState(2);
  const [chukkerRequestedTemporary, setChukkerRequestedTemporary] = useState(2);
  const [playerRating, setPlayerRating] = useState(-2);
  const [playerClubRating, setPlayerClubRating] = useState(-2);
  const [playerUSPARating, setPlayerUSPARating] = useState(-2);
  const [lastName, setLastName] = useState();
  const [firstName, setFirstName] = useState();
  //const [clubPlayers, setClubPlayer] = useState();
  const [pricingValue, setPricingValue] = useState();
  const [pricingCurrency, setPricingCurrency] = useState();
  const [membershipPackagePurchase, setMembershipPackagePurchase] = useState();
  const [validCredits, setValidCredits] = useState();
  const [totalCredits, setTotalCredits] = useState();
  const [packageReference, setPackageReference] = useState();
  const [stripeRef, setStripeRef] = useState()
  const numberChukkers = [];
  const numberChukkers0 = [];
  var i;
  //useRefresh(clubId, devPort);
  for (i = 0; i <= 10; i++) {
    numberChukkers0.push({ label: i, value: i });
    if (i > 0) {
      numberChukkers.push({ label: i, value: i });
    }
  }
  const playerRatings = [];
  for (i = -2; i <= 10; i++) {
    playerRatings.push({ label: i, value: i });
  }
  //console.log(playerDropdown)
  function updateRequestedChukkers(event, newValue) {
    console.log(newValue);
    setHorseLeaseChukkers(0);
    setChukkerRequested(newValue);
  }
  useEffect(() => {
    creditsRetriever(
      managerMode,
      devPort,
      clubId,
      gameId,
      "membership_pricing",
      setValidCredits
    );
  }, [managerMode, devPort, clubId, gameId]);

  useEffect(() => {
    if (leasePrice) {
      const fetchPriceValue =
        devPort + "get-lesson-pricing-value/" + leasePrice + "/lease_pricing";
      fetch(fetchPriceValue)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("Actual Price");
          console.log(data);
          setPricingValue(data[0][0] / 100.0);
          setPricingCurrency(data[0][1]);
        });
    }
  }, [leasePrice, devPort]);

  const [registerModal, setRegisterModal] = useState(false);
  const [idMapping, setIdMapping] = useState();
  const [idPlayer, setIdPlayer] = useState();
  const [idPro, setIdPro] = useState(-1);
  //const [playerDropdown, setPlayerDropdown] = useState();
  const [loadingClubPlayers, setLoadinClubPLayers] = useState(true);
  const [signupNotes, setSignupNotes] = useState();
  const [needHorseLease, setNeedHorseLease] = useState(false);
  const [horseLeaseChukkers, setHorseLeaseChukkers] = useState(0);
  let navigate = useNavigate();
  const getPlayerList = useCallback(() => {
    fetch(devPort + "player_list/" + clubId + "/False")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const newIdMapping = {};
        const newNameMapping = {};
        const newPlayers = data.map((game) => {
          newIdMapping[game[0] + " " + game[1]] = game[4];
          newNameMapping[game[4]] = game[0] + " " + game[1];
          if (game[4] === managerMode) {
            console.log(game[0] + " " + game[1]);
            //setPlayerDropdown(game[0] + " " + game[1]);
            setIdPlayer(game[4]);
          }
          //TODO update with player ID
          return {
            label: game[0] + " " + game[1],
            value: game[0] + " " + game[1],
          };
        });
        setIdMapping(newIdMapping);
        console.log(newPlayers);
        //setClubPlayer(newPlayers);
        setLoadinClubPLayers(false);
      });
  }, [devPort, clubId, managerMode]);
  useEffect(() => {
    getPlayerList();
  }, [getPlayerList]);

  function refresh() {
    loadPlayers();
    getPlayerList();
  }

  const [alterMessage, setAlertMessage] = useState(false);
  const currentPage = managerMode !== -1 ? "/playerregister" : "/addplayer";
  const registerText = managerMode !== -1 ? "" : "New Player ";
  const [players, setPlayers] = useState();
  const [loadingSignups, setLoadingSignups] = useState(true);
  const loadPlayers = useCallback(() => {
    setIdPro(-1);
    console.log(devPort + "player_roster/" + clubId + "/" + gameId);
    fetch(devPort + "player_roster/" + clubId + "/" + gameId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const newPLayers = data.map((player) => {
          return player[1] + " " + player[2];
        });
        setPlayers(newPLayers);
        setLoadingSignups(false);
      });
  }, [devPort, clubId, gameId]);

  //function registerPlayer(paidLease) {
  const registerPlayer = useCallback(
    (paidLease) => {
      console.log(JSON.stringify(idMapping));
      console.log(idPlayer);

      const saveSignup = devPort + "post_signup";
      console.log(saveSignup);
      console.log(signupNotes);

      const signupSave = {
        player_id: idPlayer,
        club_id: clubId,
        chukker_count: chukkerRequested,
        game_id: gameId,
        pro_id: idPro,
        signup_notes: signupNotes,
        paid_lease: paidLease,
        number_leased_chukkers: horseLeaseChukkers,
        total_credits: totalCredits,
        package_reference: packageReference,
        stripe_transaction_reference: stripeRef
      };
      if (!idPlayer) {
        window.alert("Select a player");
      } else {
        fetch(saveSignup, {
          method: "POST",
          body: JSON.stringify(signupSave),
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => {
          if (response) {
            console.log("Player has been registered");
            setAlertMessage(true);
            setLoadingSignups(true);
            loadPlayers();
            if (managerMode !== -1) {
              navigate("/playersignup");
            }
          }
        });
      }
    },
    [
      idPlayer,
      clubId,
      chukkerRequested,
      gameId,
      idPro,
      signupNotes,
      horseLeaseChukkers,
      devPort,
      idMapping,
      managerMode,
      totalCredits,
      packageReference,
      stripeRef,
      loadPlayers,
      navigate,
    ]
  );

  useEffect(() => {
    loadPlayers();
  }, [loadPlayers]);

  const [registerPlayerAfterStripe, setRegisterPlayerAfterStripe] =
    useState(false);
  const [creditRef, setCreditRef] = useState();
  const [
    registerPlayerAfterStripeUseCredit,
    setRegisterPlayerAfterStripeUseCredit,
  ] = useState(false);

  const [searchParams] = useSearchParams();
  const stripeSessionId = searchParams.get("session_id");
  useEffect(() => {
    if (registerPlayerAfterStripe) {
      registerPlayer(true);
    }
  }, [registerPlayerAfterStripe, registerPlayer]);

  const registerPlayerWithCredit = useCallback(
    (packageUsed) => {
      const saveSignup = devPort + "register_game_with_membership";
      const signupSave = {
        player_id: idPlayer,
        club_id: clubId,
        lesson_id: gameId,
        signup_notes: signupNotes,
        package_reference: packageUsed,
        chukker_number: chukkerRequested,
        number_leased_chukkers: horseLeaseChukkers,
        pro_request: idPro,
      };
      console.log(signupSave);
      if (!idPlayer) {
        window.alert("Select a player");
      } else {
        fetch(saveSignup, {
          method: "POST",
          body: JSON.stringify(signupSave),
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            console.log(data);
            console.log(data.results.message);
            setLoadingSignups(true);
            navigate("/playersignup");
          });
      }
      //setLoadingSignups(!loadingSignups);
    },
    [
      clubId,
      devPort,
      gameId,
      idPlayer,
      signupNotes,
      chukkerRequested,
      horseLeaseChukkers,
      idPro,
      navigate,
    ]
  );

  useEffect(() => {
    if (registerPlayerAfterStripeUseCredit && creditRef) {
      registerPlayerWithCredit(creditRef);
    }
  }, [registerPlayerAfterStripeUseCredit, creditRef, registerPlayerWithCredit]);
  useEffect(() => {
    if (stripeSessionId) {
      const stripeFetch =
        devPort + "retrieve-stripe-payment/" + stripeSessionId;
      console.log(stripeFetch);
      fetch(stripeFetch)
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data) {
            console.log(data);
            console.log(data["clubId"]);
            setClubId(data[0]["clubId"]);
            setGameId(data[0]["gameId"]);
            setIdPlayer(data[0]["playerId"]);
            setChukkerRequested(data[0]["chukkerNumber"]);
            setSignupNotes(data[0]["signupNotes"]);
            setLeasePrice(data[0]["lessonPriceId"]);
            setHorseLeaseChukkers(data[0]["numberLeasedChukkers"]);
            setManagerMode(parseInt(data[0]["uid"]));
            setIdPro(data[0]["proRequest"]);
            setStripeRef(data[0]["stripe_transaction_reference"])
            if (data[0]["package_reference"]) {
              setTotalCredits(data[0]["total_credits"]);
              setPackageReference(data[0]["package_reference"]);
            } else {
              setTotalCredits();
              setPackageReference("");
            }
            if (data[0]["creditRef"]) {
              setCreditRef(data[0]["creditRef"]);
              setRegisterPlayerAfterStripeUseCredit(true);
            } else {
              setRegisterPlayerAfterStripe(true);
            }
          }
        });
    }
  }, [
    devPort,
    stripeSessionId,
    setClubId,
    setGameId,
    setLeasePrice,
    setManagerMode,
  ]);
  function createCheckoutLease(creditRef) {
    if (!idPlayer) {
      window.alert("Select a player from the dropdown menu");
    } else {
      if (needHorseLease && horseLeaseChukkers < 1) {
        window.alert(
          "Select a lease number greater than 0 or unslect the 'I will need to lease some horses' option"
        );
      } else {
        fetch(devPort + "create-checkout-lease", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            playerId: idPlayer,
            uid: managerMode,
            clubId: clubId,
            gameId: gameId,
            signupNotes: signupNotes,
            chukker_number: chukkerRequested,
            lesson_price_id: leasePrice,
            number_leased_chukkers: horseLeaseChukkers,
            pro_request: idPro,
            website: window.location.href.split("#")[0],
            credit_ref: creditRef,
          }),
        })
          .then((response) => {
            console.log(response);
            return response.json();
          })
          .then((data) => {
            console.log(data);
            window.location.href = data.url;
          });
      }
    }
  }

  return (
    <Layout managerMode={managerMode} waiverNotifications={waiverNotifications} devPort={devPort}>
      <DashboardTabs
        currentPage={currentPage}
        managerMode={managerMode}
        gameMode="game"
      />
      <Snackbar
        open={alterMessage}
        autoHideDuration={3000}
        onClose={() => {
          setAlertMessage(false);
        }}
      >
        <Alert severity="success" data-testid="player-has-been-added">
          {" "}
          Player has been added to the signup list!
        </Alert>
      </Snackbar>
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <h1>
              {" "}
              Register {registerText}for Game on {gameDate}
            </h1>
            {!loadingSignups && !loadingClubPlayers && !stripeSessionId && (
              <Stack spacing={3}>
                {validCredits && <CreditsBanner validCredits={validCredits} />}
                <Grid container size={{xs:12}} spacing={2}>
                  <Grid size={{sx:6}}>
                    <PlayerBox
                      devPort={devPort}
                      clubId={clubId}
                      gameId={gameId}
                      setIdPlayer={setIdPlayer}
                      refresh={loadingSignups}
                      managerMode={managerMode}
                      playerFixedId={managerMode !== -1 ? managerMode : null}
                      mode="game"
                    />
                  </Grid>
                  <Grid size={{sx:6}}>
                    <ProBox
                      devPort={devPort}
                      clubId={clubId}
                      setIdPro={setIdPro}
                      refresh={players}
                    />
                  </Grid>
                </Grid>
                <div>Number of Chukkers:</div>
                <Box /*sx={{ width: 325 }}*/>
                  <Slider
                    aria-label="Number of Chukkers"
                    data-testid="number-chukkers"
                    min={1}
                    max={10}
                    defaultValue={2}
                    step={1}
                    onChange={updateRequestedChukkers}
                    valueLabelDisplay="auto"
                    marks={numberChukkers}
                  />
                </Box>

                {leasePrice && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={needHorseLease}
                          data-testid="need-horse-lease"
                          onChange={() => setNeedHorseLease(!needHorseLease)}
                        />
                      }
                      label="I will need to lease some horses"
                    />
                  </FormGroup>
                )}
                {needHorseLease && (
                  <Stack spacing={2}>
                    {leasePrice && pricingValue && pricingCurrency && (
                      <BannerMessage
                        message={
                          "Leasing Price has been set by the manager to " +
                          currencyFormatter(pricingValue, pricingCurrency) +
                          " per chukker"
                        }
                        color={"primary"}
                      />
                    )}
                    <BannerMessage
                      message={
                        "Number of chukkers available for lease: " +
                        availableHorseForLease
                      }
                      color={"secondary"}
                    />
                    <Typography>
                      For how many chukkers will you need to lease?
                    </Typography>
                    <Box /*sx={{ width: 325 }}*/>
                      <Slider
                        aria-label="Number of Chukkers"
                        data-testid="number-chukkers-lease"
                        min={0}
                        max={Math.max(
                          0,
                          Math.min(chukkerRequested, availableHorseForLease)
                        )}
                        //defaultValue={1}
                        value={horseLeaseChukkers}
                        step={1}
                        onChange={(event, newValue) => {
                          setHorseLeaseChukkers(newValue);
                        }}
                        valueLabelDisplay="auto"
                        marks={numberChukkers0}
                      />
                    </Box>
                  </Stack>
                )}
                <div>Note</div>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Add requests or notes here"
                  multiline
                  maxRows={3}
                  onChange={(input) => {
                    setSignupNotes(input.target.value);
                  }}
                />
                {idPlayer && !validCredits && (
                  <PurchasePackage
                    devPort={devPort}
                    clubId={clubId}
                    gameId={gameId}
                    managerMode={managerMode}
                    idPlayer={idPlayer}
                    signupNotes={signupNotes}
                    lessonPackagePurchase={membershipPackagePurchase}
                    setLessonPackagePurchase={setMembershipPackagePurchase}
                    packageType="membership"
                    chukkerRequested={chukkerRequested}
                    horseLeaseChukkers={horseLeaseChukkers}
                    leasePrice={leasePrice}
                    idPro={idPro}
                  />
                )}
                {((!validCredits &&
                  !needHorseLease &&
                  (!membershipPackagePurchase ||
                    membershipPackagePurchase.length === 0)) ||
                  managerMode === -1) && (
                  <Button
                    variant="contained"
                    data-testid="register-player"
                    color="success"
                    disabled={!idPlayer}
                    onClick={() => registerPlayer(false)}
                  >
                    {" "}
                    Register {managerMode === -1 ? "Player" : ""}
                  </Button>
                )}
                {needHorseLease &&
                  managerMode !== -1 &&
                  (!membershipPackagePurchase ||
                    membershipPackagePurchase.length === 0) &&
                  !validCredits && (
                    <Button
                      variant="contained"
                      data-testid="register-player-pay-lease"
                      color="success"
                      disabled={!idPlayer}
                      onClick={() => createCheckoutLease(null)}
                    >
                      {" "}
                      Register and Pay Horse Lease
                    </Button>
                  )}
                {managerMode !== -1 && validCredits && !needHorseLease && (
                  <Stack data-testid="credits-apply">
                    {Object.keys(validCredits).map((creditRef) => {
                      return (
                        <Button
                          variant="contained"
                          key={"register-player-credit-" + creditRef}
                          data-testid={"register-player-credit-" + creditRef}
                          color="success"
                          disabled={!idPlayer}
                          onClick={() => registerPlayerWithCredit(creditRef)}
                        >
                          {"Register using credit from " +
                            creditRef +
                            " package"}
                        </Button>
                      );
                    })}
                  </Stack>
                )}
                {managerMode !== -1 && validCredits && needHorseLease && (
                  <Stack data-testid="credits-apply-horse-lease">
                    {Object.keys(validCredits).map((creditRef) => {
                      return (
                        <Button
                          variant="contained"
                          key={
                            "register-player-horse-lease-credit-" + creditRef
                          }
                          data-testid={
                            "register-player-horse-lease-credit-" + creditRef
                          }
                          color="success"
                          disabled={!idPlayer}
                          onClick={() => createCheckoutLease(creditRef)}
                        >
                          {"Pay for Horse Lease and use credit from " +
                            creditRef +
                            " package"}
                        </Button>
                      );
                    })}
                  </Stack>
                )}
                {managerMode === -1 && false && (
                  <div>
                    {" "}
                    New player is not part of the club? Register a temporary
                    player below
                  </div>
                )}
                {managerMode === -1 && false && (
                  <Button
                    variant="contained"
                    data-testid="register-temporary-player"
                    color="success"
                    onClick={() => {
                      setRegisterModal(true);
                    }}
                  >
                    {" "}
                    Temporary Player
                  </Button>
                )}
              </Stack>
            )}
            {(loadingSignups || loadingClubPlayers) && (
              <LinearProgress color="secondary" />
            )}
          </Stack>
          <RegistrationModal
            managerMode={managerMode}
            devPort={devPort}
            editMode={false}
            clubId={clubId}
            gameId={gameId}
            playerId={0}
            playerModal={registerModal}
            setPlayerModal={setRegisterModal}
            baseEdit={true}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            rating={playerRating}
            setRating={setPlayerRating}
            playerClubRating={playerClubRating}
            setPlayerClubRating={setPlayerClubRating}
            playerUSPARating={playerUSPARating}
            setPlayerUSPARating={setPlayerUSPARating}
            chukkerNumber={chukkerRequestedTemporary}
            setChukkerNumber={setChukkerRequestedTemporary}
            refresh={refresh}
          />
        </CardContent>
      </Card>
    </Layout>
  );
}

export default AddPlayerPage;

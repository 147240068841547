import ChukkerItem from './ChukkerItem';
import Grid from '@mui/material/Grid2';
//<ul className={classes.list} ref={props.reference}>
function ChukkerList(props) {
    return (<Grid container spacing={2}>
        {Object.entries(props.chukkerList).map(([key, value]) => {
            return <Grid size={{xs:12}} key={key}>
                <ChukkerItem
                id={key}
                managerMode={props.managerMode}
                teams={value}
                referenceTeam={props.referenceTeam}
                referencePlayer={props.referencePlayer}
                useUSPARating={props.useUSPARating}
            />
            </Grid>
        })}
    </Grid>
    )
}

export default ChukkerList;
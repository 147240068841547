import {
  Button,
  Card,
  CardContent,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import EmailBox from "./EmailBox";
import { useNavigate } from "react-router-dom";
import sendInfoRequest from "./sendInfoRequest";

function UserContact({ devPort, setAlertMessage, setAlertMessageSuccess }) {
  let navigate = useNavigate();
  const [loginEmail, setLoginEmail] = useState();
  const [inquiry, setInquiry] = useState();

  return (
    <Paper elevation={24}>
      <Card variant="elevation">
        <CardContent>
          <Stack spacing={2}>
            <h5> Contact Us</h5>
            <EmailBox setLoginEmail={setLoginEmail} />
            <TextField
              id="contact-inquiry-info"
              label="Enter your message"
              data-testid="contact-inquiry-info"
              value={inquiry}
              multiline
              rows={4}
              onChange={(event) => setInquiry(event.target.value)}
              //defaultValue="Default Value"
            />
            <Button
              variant="contained"
              data-testid="submit-inquiry-info"
              color="primary"
              onClick={() =>
                sendInfoRequest(
                  devPort,
                  loginEmail,
                  inquiry,
                  setAlertMessageSuccess,
                  setAlertMessage,
                  ()=>navigate("/")
                )
              }
            >
              Contact Us
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default UserContact;

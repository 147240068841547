import { Card, CardContent, Paper, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import IndividualLesson from "./IndividualLesson";

function DayLessons({
  day,
  date,
  lessons,
  setLessonDelete,
  setLessonToDelete,
  managerMode,
  setEventId,
  setLessonPro,
  setLessonPrice,
  setGameDate
}) {
  console.log(lessons.length);
  return (
    <Paper elevation={24}>
      <Card variant="elevation" key={day}>
        <CardContent>
          <Grid container size={{xs:12}}>
            <Typography>{day + " " + date}</Typography>
          </Grid>
          {lessons.length === 0 && (
            <Typography>No Lessons available</Typography>
          )}
          {lessons.length !== 0 && (
            <Stack spacing={2}>
              {lessons.map((lesson) => {
                return (
                  <IndividualLesson
                    key={lesson.id}
                    lesson={lesson}
                    setLessonDelete={setLessonDelete}
                    setLessonToDelete={setLessonToDelete}
                    managerMode={managerMode}
                    setEventId={setEventId}
                    setLessonPro={setLessonPro}
                    setLessonPrice={setLessonPrice}
                    setGameDate={setGameDate}
                  />
                );
              })}
            </Stack>
          )}
        </CardContent>
      </Card>
    </Paper>
  );
}

export default DayLessons;

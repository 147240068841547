import TeamItem from "./TeamItem";
import Grid from '@mui/material/Grid2';
function TeamList(props) {
  return (
    <Grid container spacing={6}>
      {Object.entries(props.teamList).map(([key, value]) => {
        return (
          <Grid size={{xs:6}} key={key}>
              <TeamItem
                id={key}
                managerMode={props.managerMode}
                players={value}
                chukkerId={props.chukkerId}
                referencePlayer={props.referencePlayer}
                useUSPARating={props.useUSPARating}
              />
              </Grid>
        );
      })}
    </Grid>
  );
}

export default TeamList;

import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { Dispatch, SetStateAction } from "react";

function CancelMembershipModal({
  deleteModal,
  setDeleteModal,
}: {
  deleteModal: boolean;
  setDeleteModal: Dispatch<SetStateAction<boolean>>;
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <Modal
      open={deleteModal}
      onClose={() => {
        setDeleteModal(false);
      }}
    >
      <Box sx={style}>
        <Stack spacing={2}>
          <Typography id="modal-prompt" variant="h6" component="h2">
            Are you sure you want to cancel your membership?
          </Typography>
          <Typography id="modal-prompt">
            All the data related to your club will be lost. Your membership will
            remain active until the end of the month. Your data will be kept for
            up to a year. Data retrieval may incur a fee
          </Typography>
          <Grid container size={{xs:12}}>
            <Grid size={{xs:5.5}}>
              <Button variant="outlined" color="primary" onClick={() => {}} style={{width: "100%"}}>
                Yes
              </Button>
            </Grid>
            <Grid size={{xs:5.5}}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setDeleteModal(false);
                }}
                style={{width: "100%"}}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Modal>
  );
}

export default CancelMembershipModal;

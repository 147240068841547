import * as React from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//import Copyright from "./internals/components/Copyright";
import ChartMembersbyType from "./ChartUserByCountry";
//import CustomizedTreeView from "./CustomizedTreeView";
//import CustomizedDataGrid from "./CustomizedDataGrid";
import HighlightedCard from "./HighlightedCard";
import PageViewsBarChart from "./PageViewsBarChart";
import SessionsChart from "./SessionsChart";
import { chartData } from "../Analytics/ChartCard";
import SubscriptionUsage from "./SubscriptionUsage";
import { analyticsDataAllTime } from "../Analytics/StatsCaroussel";
import { chartLegend } from "src/pages/AnalyticsRevamp";

export default function MainGrid({
  weeklyGames,
  weeklySignups,
  weeklyLessons,
  playerStatistics,
  playerTypeStatistics,
  totalPlayers,
  weeklyLessonSignups,
  weeklyLessonRevenue,
  devPort,
  clubId,
}: {
  weeklyGames: chartData | undefined;
  weeklySignups: chartData | undefined;
  weeklyLessons: chartData | undefined;
  playerStatistics: chartLegend[];
  playerTypeStatistics: chartLegend[];
  totalPlayers: number;
  weeklyLessonSignups: chartData | undefined;
  weeklyLessonRevenue: chartData | undefined;
  devPort: string;
  clubId: number;
}) {
  const [analyticsData, setAnalyticsData] =
    React.useState<analyticsDataAllTime[]>();
  React.useEffect(() => {
    const analytics_fetch = devPort + "get_club_analytics_all/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("ALL ANALYTICS");
        console.log(data);
        if (data) {
          const newAnalyticsData = [
            /*{
              label: "Club Stats",
              description: data["club_stats"],
              iconName: "club",
            },*/
            {
              label: "Games Stats",
              description: data["games_stats"],
              iconName: "games",
            },
            {
              label: "Lesson Stats",
              description: data["lessons_stats"],
              iconName: "lessons",
            },
            {
              label: "Revenue Stats",
              description: data["revenue_stats"],
              iconName: "revenue",
            },
          ];
          setAnalyticsData(newAnalyticsData);
        }
      });
  }, [devPort, clubId]);

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      {/* cards */}
      {/*<Typography component="h2" variant="h2" sx={{ mb: 2 }} color="secondary" style={{textAlign: "center"}}>
        Club Analytics
  </Typography>*/}
      <Typography component="h2" variant="h5" sx={{ mb: 2 }} color="white">
        Club Analytics
      </Typography>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }} color="white">
        Membership Statistics
      </Typography>
      <Grid container spacing={2} columns={12}>
        {/*<Grid size={{ xs: 12, lg: 9 }}>
          <CustomizedDataGrid />
        </Grid>*/}
        <Grid size={{ xs: 12, md: 4 }}>
          {playerStatistics && playerStatistics.length !== 0 && (
              <ChartMembersbyType
                playerStatistics={playerStatistics}
                totalPlayers={totalPlayers}
                title={"Members by Account Status"}
                subtitle={""}
              />
            )}
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          {playerTypeStatistics && playerTypeStatistics.length !== 0 && (
              <ChartMembersbyType
                playerStatistics={playerTypeStatistics}
                totalPlayers={totalPlayers}
                title={"Members by Type"}
                subtitle={""}
              />
            )}
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          {/*<Stack gap={2} direction={{ xs: "column", sm: "row", lg: "column" }}>*/}
          {/*<CustomizedTreeView />*/}
          <SubscriptionUsage devPort={devPort} clubId={clubId} />
        </Grid>
      </Grid>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }} color="white">
        All-time Club Statistics
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {/*data.map((card, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, lg: 3 }}>
            <StatCard {...card} />
          </Grid>
        ))*/}
        {analyticsData?.map((analyticsTile) => (
          <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
            <HighlightedCard
              title={analyticsTile.label}
              iconName={analyticsTile.iconName}
              statsDetails={analyticsTile.description}
            />
          </Grid>
        ))}
      </Grid>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }} color="white">
        Weekly Trends
      </Typography>
      <Grid
        container
        spacing={2}
        columns={12}
        sx={{ mb: (theme) => theme.spacing(2) }}
      >
        {weeklySignups && (
          <Grid size={{ xs: 12, md: 6 }}>
            <SessionsChart
              weeklyLessonSignups={weeklySignups}
              title="Games Signups"
              subtitle="Signup Per Game Type Per Week"
            />
          </Grid>
        )}
        {weeklyGames && (
          <Grid size={{ xs: 12, md: 6 }}>
            <PageViewsBarChart
              weeklyLessons={weeklyGames}
              title="Games"
              subtitle="Games Per Type Per Week"
              trendTitle="games"
              prefix=""
            />
          </Grid>
        )}
        {weeklyLessonSignups && (
          <Grid size={{ xs: 12, md: 6 }}>
            <SessionsChart
              weeklyLessonSignups={weeklyLessonSignups}
              title="Lessons Signups"
              subtitle="Signup Per Coach Per Week"
            />
          </Grid>
        )}
        {weeklyLessons && (
          <Grid size={{ xs: 12, md: 6 }}>
            <PageViewsBarChart
              weeklyLessons={weeklyLessons}
              title="Lessons"
              subtitle="Lessons Per Coach Per Week"
              trendTitle="lessons"
              prefix=""
            />
          </Grid>
        )}
        {weeklyLessonRevenue && (
          <Grid size={{ xs: 12, md: 6 }}>
            <PageViewsBarChart
              weeklyLessons={weeklyLessonRevenue}
              title="Revenue"
              subtitle="Revenue Per Week in $"
              trendTitle=""
              prefix="$"
            />
          </Grid>
        )}
      </Grid>
      
      {/*<Copyright sx={{ my: 4 }} />*/}
    </Box>
  );
}

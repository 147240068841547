import classes from "./PlayerItem.module.css";
import TeamList from "./TeamList";
import { Card, CardContent } from "@mui/material";
import TeamItem from "./TeamItem";
import Grid from '@mui/material/Grid2';
function ChukkerItem(props) {
  return (
    <Card sx={{ mx: -1 }} data-testid={"chukker-" + props.id}>
      <CardContent>
        <h3 className={classes.contenth}>
          {" "}
          {props.id.startsWith("-") ? "Bench" : "Chukker " + props.id}{" "}
        </h3>
        {!props.id.startsWith("-") && (
          <TeamList
            managerMode={props.managerMode}
            teamList={props.teams}
            chukkerId={props.id}
            referencePlayer={props.referencePlayer}
            useUSPARating={props.useUSPARating}
          />
        )}
        {props.id.startsWith("-") && (
          <Grid container size={{xs:12}}>
            <Grid size={{xs:12}} key={"A_" + props.id}>
              <TeamItem
                id={"A_" + props.id}
                managerMode={props.managerMode}
                players={props.teams["A_" + props.id]}
                chukkerId={props.id}
                referencePlayer={props.referencePlayer}
                useUSPARating={props.useUSPARating}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
}

export default ChukkerItem;

 const chukkerbookTheme = {
  primary: "#5D8AA8",
  secondary: "#800020",
  success: "#A2D69A",
  warning: "#F5C242"
};
export type colorType = "primary" | "secondary" | "success" | "warning";
export const colors:colorType[] =["warning", "primary", "success", "secondary"]

export default chukkerbookTheme;

import * as React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

import { Gauge, gaugeClasses } from "@mui/x-charts";
import chukkerbookTheme from "src/chukkerbbokTheme";
import { IconMapper } from './IconMapper';


interface QuotaItem {
    name: string,
    icon: any,
    value: number,
    color: string,
}

export default function SubscriptionUsage({
  devPort,
  clubId,
}: {
  devPort: string;
  clubId: number;
}) {
  const [activePlayers, setActivePlayers] = React.useState()
  const [maxAccounts, setMaxAccounts] = React.useState()
  const [quotaLegend, setQuotaLegend] = React.useState<QuotaItem[]>()
  
  React.useEffect(() => {
    console.log("Checking Membership Usage...");
    const subscription_fetch = devPort + "get_club_subscription/" + clubId;
    fetch(subscription_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        if (data && "id" in data) {
          console.log("Subscription");
          console.log(data);
          setActivePlayers(data["allowed_players"]);
          setMaxAccounts(data["max_allowed_players"]);
          const newQuotaLegend = [
            {
              name: "Current Active",
              icon: <IconMapper iconName="Current Active" />,
              value: data["allowed_players"],
              color: chukkerbookTheme["secondary"],
            },
            {
              name: "Remaining",
              icon: <IconMapper iconName="Remaining" />,
              value: data["max_allowed_players"]-data["allowed_players"],
              color: chukkerbookTheme["primary"],
            }]
            setQuotaLegend(newQuotaLegend)
        } else {
          console.log("No Subscription");
          console.log(data);
        }
      });
  }, [clubId, devPort]);
  if(!activePlayers || !maxAccounts || !quotaLegend){
    return null
  }
  return (
    <Card
      variant="outlined"
      sx={{ display: "flex", flexDirection: "column", gap: "8px", flexGrow: 1 }}
    >
      <CardContent>
        <Typography component="h2" variant="subtitle2">
          Chukkerbook Subscription Quota
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Gauge width={260} height={260} value={activePlayers} valueMax={maxAccounts} 
          cornerRadius="50%"
          margin={{
            left: 30,
            right: 30,
            top: 30,
            bottom: 30,
          }}
          sx={(theme) => ({
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: 40,
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: chukkerbookTheme["secondary"],
            },
          })}/>
        </Box>
        {quotaLegend.map((quota, index) => (
          <Stack
            key={index}
            direction="row"
            sx={{ alignItems: "center", gap: 2, pb: 2 }}
          >
            {quota.icon}
            <Stack sx={{ gap: 1, flexGrow: 1 }}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  {quota.name}
                </Typography>
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {quota.value}
                </Typography>
              </Stack>
              <LinearProgress
                variant="determinate"
                aria-label="Number of users per category"
                value={quota.value*100/ maxAccounts}
                sx={{
                  [`& .${linearProgressClasses.bar}`]: {
                    backgroundColor: quota.color,
                  },
                }}
              />
            </Stack>
          </Stack>
        ))}
      </CardContent>
    </Card>
  );
}

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";

export default function TableCard({
  statsDetails,
}: {
  statsDetails: { [key: string]: number | string };
}) {
  function createData(name: string, value: number | string) {
    return { name, value };
  }
  const rows = Object.keys(statsDetails).map((info_key: string) =>
    createData(info_key, statsDetails[info_key])
  );
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  return (
    <TableContainer>
      <Table size="small" aria-label="a dense table">
        <TableBody>
          {rows.map((row) => {
            console.log(row);
            return (
              <StyledTableRow
                key={row.name}
                //sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">{row.value}</TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import { BarChart } from "@mui/x-charts";
import seriesData from "./seriesData.ts";
import ChartCard, { chartData } from "./ChartCard.tsx";


function BarChartTile({
  title,
  filled,
  chartData,
  value_formatter,
  y_label
}: {
  title: string;
  filled: boolean;
  chartData: chartData;
  value_formatter: any;
  y_label: string;
}) {
  const chartSetting = {
    yAxis: [
      {
        label: y_label,
      },
    ],
  };
  return (
    <ChartCard title={title}>
      <BarChart
        xAxis={[{ data: chartData["x"], scaleType: "band", label:"Week Of", tickPlacement:"middle"}]}
        series={seriesData(chartData, filled, value_formatter)}
        height={300}
        {...chartSetting}
      />
    </ChartCard>
  );
}

export default BarChartTile;

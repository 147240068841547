import chukkerbookTheme, { colors } from "../../chukkerbbokTheme.tsx";
import { chartData } from "./ChartCard.tsx";

function seriesData(chartData: chartData, filled: boolean, value_formatter){
    const series=Object.keys(chartData["y"]).map((y_key: string, index: number) => {
        return {
          data: chartData["y"][y_key],
          area: filled,
          label: y_key,
          color: chukkerbookTheme[colors[index%colors.length]],
          valueFormatter: value_formatter
        };
      })
    return series;
}

export default seriesData
import { Route, Routes } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
//import { TouchBackend } from 'react-dnd-touch-backend';

import MatchupPage from "./pages/Matchup";
import AddPlayerPage from "./pages/AddPlayer";
import SignupPage from "./pages/Signups";
import EventPage from "./pages/EventPage";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useCallback, useEffect, useState } from "react";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import PlayerPage from "./pages/Player";
import ClubPage from "./pages/ClubPage";
import GoogleLoginPage from "./pages/GoogleLoginPage";
import MembershipSelectionPage from "./pages/MembershipSelection";
import ManagerSubscriptionPage from "./pages/ManagerSubscription";
import MainPage from "./pages/MainPage";
import SettingsPage from "./pages/SettingsPage";
import LessonPage from "./pages/LessonRegistration";
import LessonSignupPage from "./pages/LessonSignups";
import AddLessonPlayerPage from "./pages/AddLessonPlayer";
import LessonManagePricePage from "./pages/LessonManagePricesPage";
import isSubscriber from "./components/membership/isSubscriber";
import PasswordResetPage from "./pages/PasswordResetPage";
import ContactPage from "./pages/ContactPage";
import hasPlayerSignegWaiver from "./components/PlayerManagement/hasPlayerSignedWaiver";
import getWaiverLinks from "./components/Settings/getWaiverLinks";
import AnalyticsPage from "./pages/Analytics.tsx";
import MarketingPage from "./pages/MarketingPage";
import AnalyticsRevamp from "./pages/AnalyticsRevamp";

function App() {
  //const [token, setToken] = useState();
  const [gameId, setGameId] = useState();
  const [clubId, setClubId] = useState(-1); //7); //TODO update to -1
  const [gameDate, setGameDate] = useState();
  const [gamePoloType, setGamePoloType] = useState();
  //const [userName, setUserName] = useState(window.reactPress.usermeta.nickname); //'peteymcgees');
  const [userName, setUserName] = useState(""); //peteymcgees"); //'peteymcgees');
  const [currentMembership, setCurrentMembership] = useState("");
  const [lessonPro, setLessonPro] = useState();
  const [subscriptionMode, setSubscriptionMode] = useState("create")
  const devMode = false;
  const [betaMode, setBetaMode] = useState(devMode);
  //const [userName, setUserName] = useState(devMode ? 'peteymcgees' : window.reactPress.usermeta.nickname); //'peteymcgees');
  //const [userName, setUserName] = useState('peteymcgees'); //'peteymcgees');
  /*const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#0d9cc3",
      },
      secondary: {
        main: "#f50057",
      },
      divider: "rgba(36,133,216,0.44)",
      background: {
        paper: "#463f3f",
        default: "#272525",
      },
      text: {
        secondary: "rgba(17,203,226,0.7)",
      },
    },
  });*/
  /*const tokenName = "token_chukkerbook";
  function setToken(userToken) {
    console.log("SETTING TOKEN");
    console.log(JSON.stringify(userToken));
    localStorage.setItem(tokenName, JSON.stringify(userToken));
    window.location.reload(false);
  }

  function getToken() {
    const tokenString = localStorage.getItem(tokenName);
    const userToken = JSON.parse(tokenString);
    return userToken?.token;
  }*/
  /*useEffect(() => {
    const token = getToken();
    if (token) {
      console.log("TOKEN");
      console.log(token);
      setCurrentMembership("player");
      setUserName(token);
    }
  }, []);*/
  const chukkerTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#5D8AA8",
      },
      secondary: {
        main: "#800020",
      },
      background: {
        default: "#5D8AA8",
      },
      success: {
        main: "#A2D69A",
      },
      error: {
        main: "#800020",
      },
      warning: {
        main: "#F5C242"
      }
    },
    typography: {

      button: { // Here is where you can customise the button
        textTransform: 'none'
      },    
    },  
  });
  const mainTheme = chukkerTheme;

  //const devPort = process.env.REACT_APP_MANAGER_DASHBOARD
  //  ? "https://www.chukkerbook.com:5002/"
  //  : "http://127.0.0.1:5000/";

  //const devPort = 'http://164.92.81.156:5000/'
  //const devPort = '/'
  //const devPort = 'https://172.17.0.2:5000/'
  //const devPort = 'https://www.chukkerbook.com:5002/'
  const devPort = devMode
    ? "http://127.0.0.1:5000/"
    : "https://api.react.chukkerbook.com/";
  //const devPort = "http://127.0.0.1:5000/"
  console.log(window.location);
  const [playerBoard, setPlayerBoard] = useState(-1); //101);
  const [lessonPrice, setLessonPrice] = useState();
  const [leasePrice, setLeasePrice] = useState();
  const [pricingTab, setPricingTab] = useState("Lease");
  const [availableHorseForLease, setAvailableHorseForLease] = useState(0)
  //const MANAGER_DASHBOARD = devMode || (process.env.REACT_APP_MANAGER_DASHBOARD !== "false"); //true
  //const MANAGER_DASHBOARD = true //process.env.REACT_APP_MANAGER_DASHBOARD !== "false"; //true
  useEffect(() => {
    fetch(devPort + "get_cookies", {
      credentials: "include",
      mode: "cors",
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          console.log("COOKIE COOKIE");
          if (data === "Expired session" || data === "No cookies found") {
            console.log(data);
          } else {
            setUserName(data);
          }
        }
      });
  }, [devPort]);
  useEffect(() => {
    if (userName) {
      isSubscriber(devPort, userName, setCurrentMembership);
    }
  }, [devPort, userName]);
  const [alertMessageSuccess, setAlertMessageSuccess] = useState(false);
  const [waiverNotifications, setWaiverNotifications] = useState(0);
  const getNumberMissingWaiver = useCallback(() => {
    const fetch_missing_waiver =
      devPort + "club_number_missing_waiver/" + clubId;
    console.log(fetch_missing_waiver);
    fetch(fetch_missing_waiver)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("TOP ELVE NOTIC");
        if (data && data[0]) {
          console.log(data[0][0]);
          setWaiverNotifications(data[0][0]);
          //  setRestrictedAccess(data[0][0]);
        }
        console.log(data);
      });
  }, [devPort, clubId]);

  const [waiverLinks, setWaiverLinks] = useState([]);
  useEffect(() => {
    if (clubId > -1) {
      if (playerBoard === -1) {
        getNumberMissingWaiver();
      } else {
        getWaiverLinks(devPort, clubId, setWaiverLinks);
      }
    }
  }, [clubId, devPort, playerBoard, getNumberMissingWaiver]);
  useEffect(() => {
    if (waiverLinks.length > 0) {
      hasPlayerSignegWaiver(devPort, clubId, playerBoard, (value) => {
        if (value) {
          setWaiverNotifications(0);
        } else {
          setWaiverNotifications(1);
        }
      });
    }
  }, [waiverLinks, devPort, clubId, playerBoard]);
  return (
    <ThemeProvider theme={mainTheme}>
      <CssBaseline />
      <DndProvider backend={HTML5Backend}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Routes>
            <Route
              exact
              path="/club"
              element={
                <ClubPage
                  managerMode={-1}
                  devPort={devPort}
                  clubId={clubId}
                  setClubId={setClubId}
                  userName={userName}
                  betaMode={betaMode}
                  setEventId={setGameId}
                  setLessonPro={setLessonPro}
                  setLessonPrice={setLessonPrice}
                  setLeasePrice={setLeasePrice}
                  setGameDate={setGameDate}
                  setSubscriptionMode={setSubscriptionMode}
                />
              }
            />
            <Route
              path="/game"
              element={
                <EventPage
                  setEventId={setGameId}
                  clubId={clubId}
                  setGameDate={setGameDate}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  managerMode={-1}
                  setGamePoloType={setGamePoloType}
                  setPricingTab={setPricingTab}
                  setLeasePrice={setLeasePrice}
                  betaMode={betaMode}
                />
              }
            />
            <Route
              exact
              path="/lesson"
              element={
                <LessonPage
                  setEventId={setGameId}
                  clubId={clubId}
                  setGameDate={setGameDate}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  managerMode={playerBoard}
                  setLessonPro={setLessonPro}
                  setLessonPrice={setLessonPrice}
                  betaMode={betaMode}
                  setPricingTab={setPricingTab}
                />
              }
            />
            <Route
              path="/player"
              element={
                <PlayerPage
                  clubId={clubId}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  setWaiverNotifications={setWaiverNotifications}
                  managerMode={-1}
                  betaMode={betaMode}
                />
              }
            />
            <Route
              path="/signup"
              element={
                <SignupPage
                  eventId={gameId}
                  clubId={clubId}
                  gameDate={gameDate}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  managerMode={-1}
                  setAvailableHorseForLease={setAvailableHorseForLease}
                />
              }
            />
            <Route
              path="/lessonsignup"
              element={
                <LessonSignupPage
                  eventId={gameId}
                  clubId={clubId}
                  gameDate={gameDate}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  managerMode={playerBoard}
                  betaMode={betaMode}
                />
              }
            />
            <Route
              path="/lessonmanagepricing"
              element={
                <LessonManagePricePage
                  clubId={clubId}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  pricingTabDefault={pricingTab}
                />
              }
            />
            <Route
              path="/matchup"
              element={
                <MatchupPage
                  eventId={gameId}
                  clubId={clubId}
                  gameDate={gameDate}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  managerMode={-1}
                  gamePoloType={gamePoloType}
                />
              }
            />
            <Route
              path="/addplayer"
              element={
                <AddPlayerPage
                  gameId={gameId}
                  clubId={clubId}
                  gameDate={gameDate}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  managerMode={-1}
                  leasePrice={leasePrice}
                  setLeasePrice={setLeasePrice}
                  setManagerMode={setPlayerBoard}
                  setClubId={setClubId}
                  setGameId={setGameId}
                  availableHorseForLease={availableHorseForLease}
                />
              }
            />
            <Route
              path="/addlessonplayer"
              element={
                <AddLessonPlayerPage
                  gameId={gameId}
                  clubId={clubId}
                  gameDate={gameDate}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  managerMode={playerBoard}
                  setManagerMode={setPlayerBoard}
                  lessonPro={lessonPro}
                  setClubId={setClubId}
                  setGameId={setGameId}
                  lessonPrice={lessonPrice}
                  setLessonPrice={setLessonPrice}
                  betaMode={betaMode}
                />
              }
            />

            <Route
              exact
              path="/account-login"
              element={
                <GoogleLoginPage
                  devPort={devPort}
                  userName={userName}
                  setUserName={setUserName}
                  setPlayerBoard={setPlayerBoard}
                  newSignUp={false}
                  setCurrentMembership={setCurrentMembership}
                  alertMessageSuccess={alertMessageSuccess}
                  setAlertMessageSuccess={setAlertMessageSuccess}
                  betaMode={betaMode}
                  //setToken={setToken}
                />
              }
            />
            <Route
              exact
              path="/account-signup"
              element={
                <GoogleLoginPage
                  devPort={devPort}
                  userName={userName}
                  setUserName={setUserName}
                  setPlayerBoard={setPlayerBoard}
                  newSignUp={true}
                  setCurrentMembership={setCurrentMembership}
                  betaMode={betaMode}
                  //setToken={setToken}
                />
              }
            />
            <Route
              exact
              path="/account-reset"
              element={
                <PasswordResetPage
                  devPort={devPort}
                  setAlertMessageSuccess={setAlertMessageSuccess}
                />
              }
            />
            <Route
              exact
              path="/a"
              element={
                <MainPage
                  userName={userName}
                  setUserName={setUserName}
                  devPort={devPort}
                  currentMembership={currentMembership}
                  alertMessageSuccess={alertMessageSuccess}
                  setAlertMessageSuccess={setAlertMessageSuccess}
                  setBetaMode={setBetaMode}
                />
              }
            />
            <Route
              exact
              path="/"
              element={
                <MarketingPage
                  userName={userName}
                  setUserName={setUserName}
                  devPort={devPort}
                  currentMembership={currentMembership}
                  alertMessageSuccess={alertMessageSuccess}
                  setAlertMessageSuccess={setAlertMessageSuccess}
                  setBetaMode={setBetaMode}
                />
              }
            />
            <Route
              path="/selectmembership"
              element={
                <MembershipSelectionPage
                  devPort={devPort}
                  currentMembership={currentMembership}
                  setCurrentMembership={setCurrentMembership}
                  userName={userName}
                  setUserName={setUserName}
                />
              }
            />
            <Route
              path="/managersubscription"
              element={
                <ManagerSubscriptionPage
                  devPort={devPort}
                  userName={userName}
                  setUserName={setUserName}
                  setCurrentMembership={setCurrentMembership}
                  clubId={clubId}
                  subscriptionMode={subscriptionMode}
                />
              }
            />
            <Route
              path="/user-settings"
              element={
                <SettingsPage
                  devPort={devPort}
                  userName={userName}
                  currentMembership={currentMembership}
                  setCurrentMembership={setCurrentMembership}
                  setSubscriptionMode={setSubscriptionMode}
                  setClubId={setClubId}
                />
              }
            />
            <Route
              exact
              path="/playergame"
              element={
                <EventPage
                  setEventId={setGameId}
                  clubId={clubId}
                  setGameDate={setGameDate}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  managerMode={playerBoard}
                  setLeasePrice={setLeasePrice}
                  betaMode={betaMode}
                />
              }
            />
            <Route
              exact
              path="/playerclubselection"
              element={
                <ClubPage
                  managerMode={1}
                  devPort={devPort}
                  clubId={clubId}
                  setClubId={setClubId}
                  setPlayerBoard={setPlayerBoard}
                  waiverNotifications={waiverNotifications}
                  betaMode={betaMode}
                  userName={userName}
                  setEventId={setGameId}
                  setLessonPro={setLessonPro}
                  setLessonPrice={setLessonPrice}
                  setLeasePrice={setLeasePrice}
                  setGameDate={setGameDate}
                />
              }
            />
            <Route
              exact
              path="/playerclub"
              element={
                <PlayerPage
                  clubId={clubId}
                  devPort={devPort}
                  managerMode={playerBoard}
                  waiverNotifications={waiverNotifications}
                  betaMode={betaMode}
                />
              }
            />
            <Route
              path="/playersignup"
              element={
                <SignupPage
                  eventId={gameId}
                  clubId={clubId}
                  gameDate={gameDate}
                  devPort={devPort}
                  managerMode={playerBoard}
                  waiverNotifications={waiverNotifications}
                  setAvailableHorseForLease={setAvailableHorseForLease}
                />
              }
            />
            <Route
              path="/playermatchup"
              element={
                <MatchupPage
                  eventId={gameId}
                  clubId={clubId}
                  gameDate={gameDate}
                  devPort={devPort}
                  managerMode={playerBoard}
                  waiverNotifications={waiverNotifications}
                />
              }
            />
            <Route
              path="/playerregister"
              element={
                <AddPlayerPage
                  gameId={gameId}
                  clubId={clubId}
                  gameDate={gameDate}
                  devPort={devPort}
                  managerMode={playerBoard}
                  waiverNotifications={waiverNotifications}
                  leasePrice={leasePrice}
                  setLeasePrice={setLeasePrice}
                  setManagerMode={setPlayerBoard}
                  setClubId={setClubId}
                  setGameId={setGameId}
                  availableHorseForLease={availableHorseForLease}
                />
              }
            />
            <Route
              path="/contact"
              element={
                <ContactPage
                  devPort={devPort}
                  setAlertMessageSuccess={setAlertMessageSuccess}
                />
              }
            />
            <Route
              path="/analytics"
              element={
                <AnalyticsPage
                  clubId={clubId}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  managerMode={playerBoard}
                />
              }
            />
            <Route
              path="/analyticsRevamp"
              element={
                <AnalyticsRevamp
                  clubId={clubId}
                  devPort={devPort}
                  waiverNotifications={waiverNotifications}
                  managerMode={playerBoard}
                />
              }
            />
          </Routes>
        </LocalizationProvider>
      </DndProvider>
    </ThemeProvider>
  );
}

export default App;

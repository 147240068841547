import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
} from "@mui/material";
import { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Grid from '@mui/material/Grid2';
import EmailBox from "./EmailBox";
import { useNavigate } from "react-router-dom";

function UserReset({
  devPort,
  setAlertMessage,
  setSnackMessage,
  jwtToken,
  setAlertMessageSuccess,
}) {
  let navigate = useNavigate();
  const [loginPassword, setLoginPassword] = useState();
  const [loginEmail, setLoginEmail] = useState();
  const [showPassword, setShowPassword] = useState(false);

  function resetUserEmail() {
    if (!loginEmail || !loginPassword) {
      window.alert("Set a valid email and password");
    } else {
      const system_reset_player = {
        password: loginPassword,
        email: loginEmail,
        jwt_token: jwtToken,
      };
      /*const fetch_reset_password =
        devPort +
        "system_reset_email_setter/" +
        loginEmail +
        "/" +
        loginPassword +
        "/" +
        jwtToken;*/
      const fetch_reset_password = devPort + "system_reset_email_setter";
      console.log(fetch_reset_password);
      fetch(fetch_reset_password, {
        method: "POST",
        body: JSON.stringify(system_reset_player),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("REST");
          console.log(data);
          if (data && data["message"] === "Successful Reset") {
            navigate("/account-login");
            setAlertMessageSuccess(true);
          } else {
            if (data && data["message"]) {
              setSnackMessage(data["message"]);
              setAlertMessage(true);
            }
          }
        });
    }
  }
  return (
    <Grid container size={{xs:12}}>
      <Grid size={{xs:9}} xsOffset={1.5}>
        <Paper elevation={24}>
          <Card variant="elevation">
            <CardContent>
              <Stack spacing={2}>
                <h5> Reset Your Password</h5>
                <EmailBox setLoginEmail={setLoginEmail} />
                <FormControl style={{ width: "100%" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    New Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    value={loginPassword ? loginPassword : ""}
                    onChange={(input) => {
                      console.log(input.target.value);
                      setLoginPassword(input.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          //onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={resetUserEmail}
                >
                  Reset Password
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default UserReset;

import {
  Button,
  Card,
  CardContent,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Grid from '@mui/material/Grid2';
import { useEffect, useState } from "react";

function HorseChukker({ devPort, clubId }) {
  const [horseChukker, setHorseChukker] = useState(0);

  useEffect(() => {
    console.log(devPort + "get_horse_chukker_club/" + clubId);
    fetch(devPort + "get_horse_chukker_club/" + clubId)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data && data[0]) {
          setHorseChukker(data[0][0]);
        }
      });
  }, [devPort, clubId]);

  function saveHorseChukker() {
    const horseChukkerFetch = devPort + "set_horse_chukker_club";
    const horseChukkerSave = {
      horse_chukker: horseChukker,
      club_id: clubId,
    };
    console.log(horseChukkerSave);
    if (!horseChukker) {
      window.alert("Enter a valid number of horse chukkers greater than 0");
    } else {
      fetch(horseChukkerFetch, {
        method: "POST",
        body: JSON.stringify(horseChukkerSave),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);
        });
    }
  }
  return (
    <Paper elevation={24}>
      <Card variant="elevation">
        <CardContent>
          <Stack>
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
              data-testid="horse-chukker-disclaimer"
            >
              Specify the total number of horse chukkers you have (if you have 6
              horses who can play 2 chukkers each, you number of horse chukker
              should be 12)
            </Typography>
            <Grid container size={{xs:12}} spacing={2}>
              <Grid size={{xs:8}}>
                <TextField
                  id="add-horse-chukker"
                  label="Number of Horse Chukker"
                  data-testid="add-horse-chukker"
                  style={{ width: "100%" }}
                  value={horseChukker}
                  onChange={(input) => {
                    console.log(input.target.value);
                    const intLesson = parseInt(input.target.value);
                    if (!Number.isNaN(intLesson)) {
                      setHorseChukker(intLesson);
                    } else {
                      setHorseChukker("");
                    }
                  }}
                />
              </Grid>
              <Grid size={{xs:4}}>
                <Button
                  variant="contained"
                  data-testid="register-horse-chukker"
                  onClick={() => {
                    saveHorseChukker();
                    console.log("HERE");
                  }}
                  color="success"
                  fullWidth={true}
                  style={{
                    height: "100%",
                  }}
                >
                  <SaveIcon />
                  {"Apply"}
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default HorseChukker;

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { BarChart } from "@mui/x-charts/BarChart";
import { chartData } from "../Analytics/ChartCard";
import chukkerbookTheme, { colors } from "src/chukkerbbokTheme";

export default function PageViewsBarChart({
  weeklyLessons,
  title,
  subtitle,
  trendTitle,
  prefix
}: {
  weeklyLessons: chartData;
  title: string;
  subtitle: string;
  trendTitle: string;
  prefix: string
}) {

  return (
    <Card variant="outlined" sx={{ width: "100%" }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          {title}
        </Typography>
        <Stack sx={{ justifyContent: "space-between" }}>
          <Stack
            direction="row"
            sx={{
              alignContent: { xs: "center", sm: "flex-start" },
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography variant="h4" component="p">
              {prefix + weeklyLessons["trend"]}
            </Typography>
            <Typography component="h2" variant="subtitle2">
              {trendTitle + " in the latest available 4 weeks "}
            </Typography>
            <Chip
              size="small"
              color={weeklyLessons["pc_trend"] >= 0 ? "success" : "error"}
              label={weeklyLessons["pc_trend"] + "%"}
            />
          </Stack>
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {subtitle}
          </Typography>
        </Stack>
        <BarChart
          borderRadius={8}
          colors={colors.map((color) => chukkerbookTheme[color])} // colorPalette}
          xAxis={
            [
              {
                scaleType: "band",
                categoryGapRatio: 0.5,
                data: weeklyLessons["x"], //['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
              },
            ] as any
          }
          series={
            Object.keys(weeklyLessons["y"]).map((proData: string) => {
              return {
                id: proData,
                label: proData,
                data: weeklyLessons["y"][proData], //[2234, 3872, 2998, 4125, 3357, 2789, 2998],
                stack: "A",
                valueFormatter: (value: number | null) => `${prefix} ${value}` 
              };
            })
            /*[
            {
              id: 'page-views',
              label: 'Page views',
              data: [2234, 3872, 2998, 4125, 3357, 2789, 2998],
              stack: 'A',
            },
            {
              id: 'downloads',
              label: 'Downloads',
              data: [3098, 4215, 2384, 2101, 4752, 3593, 2384],
              stack: 'A',
            },
            {
              id: 'conversions',
              label: 'Conversions',
              data: [4051, 2275, 3129, 4693, 3904, 2038, 2275],
              stack: 'A',
            },
          ]
        */
          }
          height={250}
          margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
          grid={{ horizontal: true }}
          slotProps={{
            legend: {
              hidden: true,
            },
          }}
        />
      </CardContent>
    </Card>
  );
}

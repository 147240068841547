import { Typography } from "@mui/material";
import { Parallax } from "react-parallax";
import MainHorses from "../../pages/MainHorsesBW.jpeg";

import "./pages.css";
import { ParallaxBanner } from "react-scroll-parallax";
import chukkerbookTheme from "../../chukkerbbokTheme";
function BackgroundParallax() {
  const advancedLib = true;
  if (!advancedLib) {
    return (
      <Parallax className="image" bgImage={MainHorses} strength={700}>
        <Typography
          className="content"
          variant="h2"
          color={chukkerbookTheme["warning"]}
          style={{ textShadow: "0 0 30px black" }}
        >
          <strong>Spend less time planning and more time playing</strong>
        </Typography>
      </Parallax>
    );
  } else {
    return (
      <ParallaxBanner
        layers={[{ image: MainHorses, speed: -20 }]}
        className="image"
      >
        <Typography
          className="content"
          variant="h2"
          color={chukkerbookTheme["warning"]}
          style={{ textShadow: "0 0 30px black", textAlign: "center" }}
        >
          <strong>Spend less time planning and more time playing</strong>
        </Typography>
      </ParallaxBanner>
    );
  }
}

export default BackgroundParallax;

import PlayerItem from "./PlayerItem";
import Grid from '@mui/material/Grid2';
function PlayerList(props) {
  console.log(props.playerList)
  return (
    <Grid container spacing={2}>
      {Object.entries(props.playerList).map(([key, players]) => {
        return (
          <Grid size={{xs:12}} key={key}>
            <PlayerItem
              id={key}
              managerMode={props.managerMode}
              firstName={players[4]}
              lastName={players[5]}
              rating={props.useUSPARating ? players[6] : players[7]}
              chukker={props.chukker}
              team={props.team}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default PlayerList;

import { Card, CardContent, Paper, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
// import DayLessons from "../components/Lessons/DayLesson";
import { useEffect, useState } from "react";
import getLessonsByClub from "./getLessonsByClub";
import IndividualLesson from "../Lessons/IndividualLesson";
import IndividualGame from "./IndividualGame";
import getProMapping from "../Signups/getProMapping";

function GameOrLessonCard({
  lesson_day,
  lesson,
  setLessonDelete,
  setLessonToDelete,
  managerMode,
  setEventId,
  setLessonPro,
  setLessonPrice,
  setGameDelete,
  setGameToDelete,
  clubId,
  setClubId,
  setLeasePrice,
  setGameDate,
}) {
  console.log(lesson.event_type);
  console.log(lesson);
  if (lesson.event_type === "lesson") {
    return (
      <Stack key={lesson.id}>
        <IndividualLesson
          key={lesson.id}
          lesson={lesson}
          setLessonDelete={setLessonDelete}
          setLessonToDelete={setLessonToDelete}
          managerMode={managerMode}
          setEventId={setEventId}
          setLessonPro={setLessonPro}
          setLessonPrice={setLessonPrice}
          clubId={clubId}
          setClubId={setClubId}
          setGameDate={setGameDate}
        />
      </Stack>
    );
  } else {
    return (
      <Stack key={lesson.id}>
        <IndividualGame
          key={lesson.id}
          game={lesson}
          setGameDelete={setGameDelete}
          setGameToDelete={setGameToDelete}
          managerMode={managerMode}
          setEventId={setEventId}
          clubId={clubId}
          setClubId={setClubId}
          setLeasePrice={setLeasePrice}
          setGameDate={setGameDate}
        />
      </Stack>
    );
  }
}

function ClubPreviewCard({
  club,
  managerMode,
  devPort,
  setLessonPro,
  setLessonPrice,
  setEventId,
  setGameDelete,
  setGameToDelete,
  setClubId,
  setLeasePrice,
  setGameDate,
}) {
  const [proMapping, setProMapping] = useState();
  useEffect(() => {
    const player_fetcher = devPort + "player_list/" + club.value + "/True";
    getProMapping(
      devPort,
      player_fetcher,
      setProMapping,
      () => {},
      () => {} //setLoadingClubPros
    );
  }, [devPort, club]);
  const [upcomingLessons, setUpcomingLessons] = useState([]);
  const setLessonDelete = () => {};
  const setLessonToDelete = () => {};
  useEffect(() => {
    if (proMapping) {
      getLessonsByClub(devPort, club.value, setUpcomingLessons, proMapping);
    }
  }, [devPort, club, proMapping]);

  return (
    <Paper elevation={24}>
      <Card variant="elevation" key={club.label}>
        <CardContent>
          <Grid container size={{xs:12}}>
            <h2>{club.label}</h2>
          </Grid>
          {upcomingLessons.length === 0 && (
            <Typography>No upcoming events</Typography>
          )}
          {upcomingLessons.length !== 0 && (
            <Stack spacing={2}>
              {upcomingLessons.map((lesson_day) => {
                return (
                  <>
                    <Typography>
                      {lesson_day.day + " " + lesson_day.date}
                    </Typography>
                    {lesson_day.lessons.map((lesson) => {
                      console.log(lesson);
                      return (
                        <GameOrLessonCard
                          lesson_day={lesson_day}
                          lesson={lesson}
                          setLessonDelete={setLessonDelete}
                          setLessonToDelete={setLessonToDelete}
                          managerMode={managerMode}
                          setEventId={setEventId}
                          setLessonPro={setLessonPro}
                          setLessonPrice={setLessonPrice}
                          setGameDelete={setGameDelete}
                          setGameToDelete={setGameToDelete}
                          setClubId={setClubId}
                          clubId={club.value}
                          setLeasePrice={setLeasePrice}
                          setGameDate={setGameDate}
                        />
                      );
                    })}
                  </>
                );
              })}
            </Stack>
          )}
        </CardContent>
      </Card>
    </Paper>
  );
}

export default ClubPreviewCard;

import { Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import LineChartTile from "../components/Analytics/LineChartTile.tsx";
import { Stack } from "@mui/system";
import Grid from '@mui/material/Grid2';
import BarChartTile from "../components/Analytics/BarChartTile.tsx";
import TextMobileStepper from "../components/Analytics/StatsCaroussel.tsx";
import useIsMobileDisplay from "src/components/membership/useIsMobileDisplay";

function AnalyticsPage({
  clubId,
  devPort,
  waiverNotifications,
  //setWaiverNotifications,
  managerMode,
}: {
  clubId: number;
  devPort: string;
  waiverNotifications: number;
  //setWaiverNotifications: Dispatch<SetStateAction<number>>;
  managerMode: number;
}) {
  const [tileWidth, setTileWidth] = useState(6);
  const [weeklyGames, setWeeklyGames] = useState();
  const [weeklySignups, setWeeklySignups] = useState();

  const [weeklyLessons, setWeeklyLessons] = useState();
  const [weeklyLessonSignups, setWeeklyLessonSignups] = useState();

  const [weeklyLessonRevenue, setWeeklyLessonRevenue] = useState();

  const [isMobileDisplay] = useIsMobileDisplay()
  useEffect(()=>{
    if(isMobileDisplay){
      setTileWidth(12);
    } else {
      setTileWidth(6);
    }
  },[isMobileDisplay])

  //const chukkers = { x: [1, 2, 3, 4, 5, 6], y: { "outdoor": [10, 20, 20, 30, 10, 40], "indoor": [8, 12, 10, 6, 3, 7] } }
  //const lessons = { x: [1, 2, 3, 4, 5, 6], y: { "outdoor": [8, 12, 10, 6, 3, 7], "indoor": [10, 20, 20, 30, 10, 40] } }
  //const players = { x: [1, 2, 3, 4, 5, 6], y: { "arena": [32, 44, 48, 42, 57, 41], "grass": [10, 20, 20, 30, 10, 40] } }
  //const revenue = { x: [1, 2, 3, 4, 5, 6], y: { "US Dollars": [1000, 2000, 2500, 2500, 1500, 3000] } }

  useEffect(() => {
    const analytics_fetch = devPort + "get_club_analytics/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("ANALYTICS DATA")
        console.log(data);
        setWeeklyGames(data['weekly_games'])
        setWeeklySignups(data['weekly_signups'])
      });
  }, [devPort, clubId]);

  useEffect(() => {
    const analytics_fetch = devPort + "get_club_lesson_analytics/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("LESSON ANALYTICS DATA")
        console.log(data);
        setWeeklyLessons(data['weekly_lessons'])
        setWeeklyLessonSignups(data['weekly_signups'])
      });
  }, [devPort, clubId]);

  useEffect(() => {
    const analytics_fetch = devPort + "get_club_revenue_analytics/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("REVENUE ANALYTICS DATA")
        console.log(data);
        setWeeklyLessonRevenue(data['total_weekly_lesson_revenue'])
      });
  }, [devPort, clubId]);
  
  return (
    <Layout managerMode={managerMode} waiverNotifications={waiverNotifications} devPort={devPort}>
      <Card variant="outlined">
        <CardContent>
          <Stack gap="md">
            <h1>Analytics Dashboard</h1>
            <Grid container spacing={2}>
            <Grid size={{xs:12}}>
            <TextMobileStepper devPort={devPort} clubId={clubId}/>
            </Grid>
              {weeklySignups &&
                <Grid size={{xs:tileWidth}}>
                  <LineChartTile
                    title="Weekly Player Signups"
                    filled={false}
                    chartData={weeklySignups}
                  />
                </Grid>

              }
              {weeklyGames &&
                <Grid size={{xs:tileWidth}}>
                  <BarChartTile
                    title="Games Per Week"
                    filled={false}
                    chartData={weeklyGames}
                    value_formatter={null}
                    y_label="Games / Week"
                  />
                </Grid>
              }
              {weeklyLessonSignups &&
                <Grid size={{xs:tileWidth}}>
                  <LineChartTile
                    title="Weekly Lesson Signups"
                    filled={false}
                    chartData={weeklyLessonSignups}
                  />
                </Grid>
              }
              {weeklyLessons &&

                <Grid size={{xs:tileWidth}}>
                  <BarChartTile
                    title="Lessons Per Week"
                    filled={false}
                    chartData={weeklyLessons}
                    value_formatter={null}
                    y_label="Lessons / Week"
                  />
                </Grid>
              }
              {weeklyLessonRevenue &&
                <Grid size={{xs:tileWidth}}>
                  <BarChartTile
                    title="Lesson Revenue Per Week"
                    filled={true}
                    chartData={weeklyLessonRevenue}
                    value_formatter = {(value: number | null) => `$ ${value}`}
                    y_label="Revenue in $ / week"
                  />
                </Grid>
              }
              {/*<Grid2 xs={tileWidth}>
                <LineChartTile
                  title="Number of players per month"
                  filled={false}
                  chartData={players}
                />
              </Grid2>
              <Grid2 xs={tileWidth}>
                <LineChartTile
                  title="Stripe revenue per month"
                  filled={true}
                  chartData={revenue}
                />
            </Grid2>*/}
            </Grid>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}
export default AnalyticsPage;

import SportsScoreIcon from "@mui/icons-material/SportsScore";
import SchoolIcon from "@mui/icons-material/School";
import GroupsIcon from "@mui/icons-material/Groups";
import SavingsIcon from '@mui/icons-material/Savings';
import PsychologyIcon from '@mui/icons-material/Psychology';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';

export function IconMapper({ iconName }: { iconName: string }) {
  if (iconName === "club") {
    return <GroupsIcon color="secondary" />;
  } else if (iconName === "games") {
    return <SportsScoreIcon color="secondary" />;
  } else if (iconName === "lessons") {
    return <SchoolIcon color="secondary" />;
  } else if (iconName === "revenue") {
    return <SavingsIcon color="secondary" />;
  } else if (iconName === "Pro Accounts") {
    return <SchoolIcon color="secondary" />;
  } else if (iconName === "Amateur Accounts") {
    return <PsychologyIcon color="secondary" />;
  } else if (iconName === "Active Accounts") {
    return <LoyaltyIcon color="secondary" />;
  } else if (iconName === "Other Accounts") {
    return <LocalOfferIcon color="secondary" />;
  } else if (iconName === "Current Active") {
    return <DataUsageIcon color="secondary" />;
  } else if (iconName === "Remaining") {
    return <DataSaverOnIcon color="secondary" />;
  }
  return null
}
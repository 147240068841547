import * as React from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import sendInfoRequest from "../membership/sendInfoRequest";

export default function Contact({
  devPort,
  setAlertMessageSuccess,
  setAlertMessage,
}: {
  devPort: string;
  setAlertMessageSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertMessage: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [statusSender, setStatusSender] = React.useState<"manager" | "player">(
    "manager"
  );
  const [senderEmail, setSenderEmail] = React.useState("");
  const navigate = useNavigate();
  function resetter() {
    setSenderEmail("");
    setStatusSender("manager");
  }
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        //gap: { xs: 4, sm: 8 },
        //py: { xs: 8, sm: 10 },
        textAlign: { sm: "left", md: "left" },
      }}
    >
      <Stack spacing={2}>
        <Typography color="white" variant="h4">
          Contact us to get started
        </Typography>
        <FormControl>
          <FormLabel id="sender-status" style={{ color: "white" }}>
            I am a
          </FormLabel>
          <RadioGroup
            aria-labelledby="status-sender-radio"
            name="status-sender-radio"
            value={statusSender}
            onChange={(event) => setStatusSender(event.target.value)}
            style={{ textAlign: "center" }}
            row
          >
            <FormControlLabel
              value="manager"
              control={<Radio color="secondary" />}
              label="Club Manager"
              style={{ color: "white" }}
            />
            <FormControlLabel
              value="player"
              control={<Radio color="secondary" />}
              label="Polo Player"
              style={{ color: "white" }}
            />
          </RadioGroup>
        </FormControl>
        <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
          Email
        </InputLabel>
        <TextField
          id="email-hero"
          hiddenLabel
          size="small"
          variant="outlined"
          aria-label="Enter your email address"
          placeholder="Your email address"
          fullWidth
          color="secondary"
          focused
          value={senderEmail}
          sx={{ input: { color: "white" } }}
          slotProps={{
            htmlInput: {
              autoComplete: "off",
              "aria-label": "Enter your email address",
            },
          }}
          onChange={(event) => setSenderEmail(event.target.value)}
        />
        <Button
          variant="contained"
          color="secondary"
          size="small"
          sx={{ minWidth: "fit-content" }}
          onClick={() =>
            sendInfoRequest(
              devPort,
              senderEmail,
              statusSender,
              setAlertMessageSuccess,
              setAlertMessage,
              resetter
            )
          }
        >
          Contact Us
        </Button>
        <Stack direction="row">
          <Typography
            variant="caption"
            color="white"
            sx={{ textAlign: "center" }}
          >
            To send us a custom message, you can also use the&nbsp;
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              textDecorationLine: "underline",
              cursor: "pointer",
            }}
            variant="caption"
            color="white"
            onClick={() => navigate("/contact")}
          >
            contact form
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
}

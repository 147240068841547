import { useEffect, useState } from "react";

const useIsMobileDisplay = () => {
  const [isMobileDisplay, setIsMobileDisplay] = useState(false);
  function handleResize() {
    if (window.innerWidth < 900) {
      setIsMobileDisplay(true);
    } else {
      setIsMobileDisplay(false);
    }
  }
  useEffect(() => {
    handleResize();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return [isMobileDisplay]
};

export default useIsMobileDisplay;

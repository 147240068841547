import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import ChartCard from "./ChartCard.tsx";
import AllTimeStats from "./AllTimeStats.tsx";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

/*const steps = [
  {
    label: "Club stats",
    description: {
      Members: 152,
      Signups: 1134,
      Pros: 12,
    },
    iconName: "club",
  },
  {
    label: "Games stats",
    description: {
      "Games Played": 158,
      "Player Signups": 782,
      "Visiting Pros": 8,
      Players: 142,
    },
    iconName: "games",
  },
  {
    label: "Lesson stats",
    description: {
      "Lessons taught": 78,
      "Player Signups": 352,
      Teachers: 4,
    },
    iconName: "lessons",
  },
  {
    label: "Revenue stats",
    description: {
      Transactions: 75,
      "Lease revenue": 2500,
      "Lesson revenue": 5000,
      "Average revenue per transaction": 100,
    },
    iconName: "revenue",
  },
];*/

export interface analyticsDataAllTime {
label: string;
description: { [y_key: string]: number | string };
iconName: string;
}
  
export default function TextMobileStepper({
  devPort,
  clubId,
}: {
  devPort: string;
  clubId: number;
}) {
  const [numberTile, setNumberTile] = useState(1);
  const [analyticsData, setAnalyticsData] = useState<analyticsDataAllTime[]>();

  function handleResize() {
    if (window.innerWidth < 500) {
      setNumberTile(1);
    } else if (window.innerWidth < 1000) {
      setNumberTile(2);
    } else {
      setNumberTile(3);
    }
  }
  useEffect(() => {
    handleResize();
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: numberTile,
    slidesToScroll: numberTile,
  };

  useEffect(() => {
    const analytics_fetch = devPort + "get_club_analytics_all/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("ALL ANALYTICS");
        console.log(data);
        if (data) {
          const newAnalyticsData = [
            {
              label: "Club Stats",
              description: data["club_stats"],
              iconName: "club",
            },
            {
              label: "Games Stats",
              description: data["games_stats"],
              iconName: "games",
            },
            {
              label: "Lesson Stats",
              description: data["lessons_stats"],
              iconName: "lessons",
            },
            {
              label: "Revenue Stats",
              description: data["revenue_stats"],
              iconName: "revenue",
            },
          ];
          setAnalyticsData(newAnalyticsData);
        }
      });
  }, [devPort, clubId]);

  if (!analyticsData) {
    return null;
  }
  return (
    <ChartCard title="All Time Stats">
      <Slider {...settings}>
        {analyticsData.map((step) => {
          return (
            <Box sx={{ height: 280, maxWidth: 400, width: "100%", p: 2 }}>
              <AllTimeStats
                title={step.label}
                members_details={step.description}
                iconName={step.iconName}
              />
            </Box>
          );
        })}
      </Slider>
    </ChartCard>
  );
}

import { Card, CardContent, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import SchoolIcon from "@mui/icons-material/School";
import GroupsIcon from "@mui/icons-material/Groups";
import SavingsIcon from '@mui/icons-material/Savings';

/*type member_stats_interface_key =
  | "Games Played"
  | "Player Signups"
  | "Visiting Pros"
  | "Players"
  | "Lessons taught"
  | "Player Signups"
  | "Teachers"
  | "Members"
  | "Signups"
  | "Pros"
  | "Transactions"
  | "Lease revenue"
  | "Lesson revenue"
  | "Average revenue per transaction";

interface games_stats_interface {
  "Games Played": number;
  "Player Signups": number;
  "Visiting Pros": number;
  Players: number;
}

interface lesson_stats_interface {
  "Lessons taught": number;
  "Player Signups": number;
  Teachers: number;
}

interface clubs_stats_interface {
  Members: number;
  Signups: number;
  Pros: number;
}

interface revenue_stats_interface {
  Transactions: number;
  "Lease revenue": number;
  "Lesson revenue": number;
  "Average revenue per transaction": number;
}*/

function IconMapper({ iconName }: { iconName: string }) {
  if (iconName === "club") {
    return <GroupsIcon color="secondary" />;
  } else if (iconName === "games") {
    return <SportsScoreIcon color="secondary" />;
  } else if (iconName === "lessons") {
    return <SchoolIcon color="secondary" />;
  } else if (iconName === "revenue") {
    return <SavingsIcon color="secondary" />;
  }
  return null
}
function AllTimeStats({
  title,
  members_details,
  iconName,
}: {
  title: string;
  members_details: {[key: string]: number | string};
    /*| clubs_stats_interface
    | games_stats_interface
    | lesson_stats_interface
    | revenue_stats_interface;*/
  iconName: string;
}) {
  if (Object.keys(members_details).length === 0) {
    return null;
  }
  return (
    <Card
      variant="outlined"
      style={{ borderColor: "#800020", borderWidth: "medium", height: 255 }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <IconMapper iconName={iconName} />
            <Typography variant="body1" color="secondary">
              {title}
            </Typography>
          </Stack>

          {Object.keys(members_details).map(
            (info_key: string) => {
              return (
                <Grid container size={{xs:12}}>
                  <Grid size={{xs:6}}>
                    <Typography color="primary">{info_key}</Typography>
                  </Grid>
                  <Grid size={{xs:6}}>{members_details[info_key]}</Grid>
                </Grid>
              );
            }
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default AllTimeStats;

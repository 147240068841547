import { Dispatch, SetStateAction } from "react";

function sendInfoRequest(devPort: string, loginEmail: string, inquiry: string, setAlertMessageSuccess: Dispatch<SetStateAction<boolean>>, setAlertMessage: Dispatch<SetStateAction<boolean>>, postProcess: any) {
    if (!loginEmail || !inquiry) {
      window.alert("Set a valid email and message");
    } else {
      const contact_message = {
        email: loginEmail,
        message: inquiry,
      };
      console.log(contact_message);
      const fetch_contact_chukkerbook = devPort + "contact_chukkerbook";
      fetch(fetch_contact_chukkerbook, {
        method: "POST",
        body: JSON.stringify(contact_message),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log("REST");
          console.log(data);
          if (data && data["message"] === "Successful Contact") {
            setAlertMessageSuccess(true);
            postProcess()
          } else {
            setAlertMessage(true);
          }
        });
    }
  }

  export default sendInfoRequest
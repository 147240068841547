import { Card, CardContent, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

type member_stats_interface_key =
  | "Total Players"
  | "Club Pros"
  | "Chukkerbook Accounts"
  | "Active Accounts";
interface member_stats_interface {
  "Total Players": number;
  "Club Pros": number;
  "Chukkerbook Accounts": number;
  "Active Accounts": number;
}

function PlayerUsage({
  members_details,
}: {
  members_details: member_stats_interface;
}) {
  if (Object.keys(members_details).length === 0) {
    return null;
  }
  return (
    <Card
      variant="outlined"
      style={{ borderColor: "#800020", borderWidth: "medium" }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <AccountCircleIcon color="secondary" />
            <Typography variant="body1" color="secondary">
              Member Statistics
            </Typography>
          </Stack>

          {Object.keys(members_details).map(
            (info_key: member_stats_interface_key) => {
              return (
                <Grid container size={{ xs: 12 }}>
                  <Grid size={{ xs: 6 }}>
                    <Typography color="primary">{info_key}</Typography>
                  </Grid>
                  <Grid size={{ xs: 6 }}>{members_details[info_key]}</Grid>
                </Grid>
              );
            }
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default PlayerUsage;

import { Card, CardContent, Stack, Typography } from "@mui/material";
import Grid from '@mui/material/Grid2';
import Edit from "./EditButton";
import Delete from "./DeleteButton";
import PaidLease from "./PaidLease";
import ProRequest from "./ProRequest";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ChukkerbookAccount from "./ChukkerbbokAccount";
import SignedWaiver from "./SignedWaiver";
import ProPlayer from "./ProPlayer";

function MyPlayerSignup({
  playerInfo,
  proColorMapping,
  setplayerModalEdit,
  setPlayerId,
  setPlayerFirstName,
  setPlayerLastName,
  setPlayerRating,
  setPlayerClubRating,
  setPlayerUSPARating,
  setPlayerIsPro,
  setChukkerNumber,
  setProRequest,
  setPlayerHasSignedWaiver,
  setPlayerModal,
  setDeleteModal,
  proMapping,
  page,
}) {
  console.log("PRO QRESTER");
  console.log(playerInfo["selected_pro_id"]);
  console.log(proColorMapping);
  const user_details =
    page === "games"
      ? {
          "Player Name":
            playerInfo["first_name"] + " " + playerInfo["last_name"],
          "Chukkers Requested": playerInfo["count_chukkers_requested"],
          "Leased Chukkers": playerInfo["count_chukkers_leased"]
            ? playerInfo["count_chukkers_leased"]
            : "-",
          "Pro Request": (
            <ProRequest
              requestedPro={playerInfo["selected_pro_id"]}
              proColorMapping={proColorMapping}
              proMapping={proMapping}
            />
          ),
          Note: playerInfo["signup_notes"],
          "Paid Lease": <PaidLease hasPaid={playerInfo["paid_lease"]} />,
        }
      : page === "lessons"
      ? {
          "Player Name":
            playerInfo["first_name"] + " " + playerInfo["last_name"],
          Coach: (
            <ProRequest
              requestedPro={playerInfo["coach"]}
              proColorMapping={proColorMapping}
              proMapping={proMapping}
            />
          ),
          Note: playerInfo["signup_notes"],
          "Paid Lesson": <PaidLease hasPaid={playerInfo["paid_lesson"]} />,
        }
      : page === "members"
      ? {
          "Player Name":
            playerInfo["first_name"] + " " + playerInfo["last_name"],
          "Chukkerbook Account": (
            <ChukkerbookAccount
              hasAccount={playerInfo["has_chukkerbook_account"]}
            />
          ),
          "Pro Player": <ProPlayer isPro={playerInfo["is_club_pro"]} />,
          "Signed Waiver": (
            <SignedWaiver hasSigned={playerInfo["up_to_date_waiver"]} />
          ),
        }
      : {};
  return (
    <Card
      variant="outlined"
      style={{ borderColor: "#800020", borderWidth: "medium" }}
    >
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" gap={1}>
            <AccountCircleIcon color="secondary" />
            <Typography variant="body1" color="secondary">
              {"My " + (page==="members" ? "" : "Signup ") + "Details"}
            </Typography>
          </Stack>

          {Object.keys(user_details).map((info_key) => {
            return (
              <Grid container size={{xs:12}}>
                <Grid size={{xs:6}}>
                  <Typography color="primary">{info_key}</Typography>
                </Grid>
                <Grid size={{xs:6}}>{user_details[info_key]}</Grid>
              </Grid>
            );
          })}
          <Grid container size={{xs:12}}>
            <Grid size={{xs:6}}>
              <Edit
                playerInfo={playerInfo}
                setplayerModalEdit={setplayerModalEdit}
                setPlayerId={setPlayerId}
                setPlayerFirstName={setPlayerFirstName}
                setPlayerLastName={setPlayerLastName}
                setPlayerRating={setPlayerRating}
                setPlayerClubRating={setPlayerClubRating}
                setPlayerUSPARating={setPlayerUSPARating}
                setPlayerIsPro={setPlayerIsPro}
                setChukkerNumber={setChukkerNumber}
                setProRequest={setProRequest}
                setPlayerHasSignedWaiver={setPlayerHasSignedWaiver}
                setPlayerModal={setPlayerModal}
                page={page}
              />
            </Grid>
            <Grid size={{xs:6}}>
              <Delete
                playerInfo={playerInfo}
                setPlayerId={setPlayerId}
                setPlayerFirstName={setPlayerFirstName}
                setPlayerLastName={setPlayerLastName}
                setDeleteModal={setDeleteModal}
              />
            </Grid>
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default MyPlayerSignup;

import {
  Button,
  Card,
  CardContent,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import UserLogin from "../membership/UserLogin";
import processCode from "./processCode";

function SignInCard({
  devPort,
  setUserName,
  newSignUp,
  setAlertMessage,
  setSnackMessage,
  setAlertMessageSuccess,
  setSnackMessageSuccess,
  signupError,
  betaMode
}: {
  devPort: string;
  setUserName: Dispatch<SetStateAction<string>>;
  newSignUp: boolean;
  setAlertMessage: Dispatch<SetStateAction<boolean>>;
  setSnackMessage: Dispatch<SetStateAction<string>>;
  setAlertMessageSuccess: Dispatch<SetStateAction<boolean>>;
  setSnackMessageSuccess: Dispatch<SetStateAction<string>>;
  signupError: string
  betaMode: boolean
}) {
  const navigate = useNavigate();

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (data: any) => {
      console.log("GOOGLE AUTH DATA");
      console.log(data);
      processCode(data.code, "get_google_code", devPort, setUserName, window.location.href);
    },
    scope: "https://www.googleapis.com/auth/userinfo.profile",
  });

  return (
    <Stack spacing={4}>
      <Paper elevation={24}>
        <Card variant="elevation">
          <CardContent>
            <Stack spacing={6}>
              <Stack spacing={3} textAlign="center">
                {!newSignUp && (
                  <Typography variant="h5" color="primary">
                    {" "}
                    Log in to your account
                  </Typography>
                )}
                {newSignUp && (
                  <Typography variant="h5" color="primary">
                    {" "}
                    Sign up for Chukkerbook
                  </Typography>
                )}
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => googleLogin()}
                  startIcon={
                    <img
                      style={{ width: "24px" }}
                      src={
                        "https://fonts.gstatic.com/s/i/productlogos/googleg/v6/24px.svg"
                      }
                      alt="Google Logo"
                    />
                  }
                >
                  {newSignUp ? "Sign up" : "Continue"} with Google
                </Button>
                {newSignUp && (
                  <AppleLogin
                    clientId="com.chukkerbook.signin"
                    redirectURI="https://chukkerbook.com/account-signup"
                    responseMode="query"
                    render={(
                      renderProps //Custom Apple Sign in Button
                    ) => (
                      <Button
                        fullWidth
                        variant="outlined"
                        //onClick={() => login()}
                        onClick={renderProps.onClick}
                        startIcon={
                          <img
                            style={{ width: "24px" }}
                            src={
                              "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
                            }
                            alt="Apple Logo"
                          />
                        }
                      >
                        Sign up with Apple
                      </Button>
                    )}
                  />
                )}
                {!newSignUp && (
                  <AppleLogin
                    clientId="com.chukkerbook.signin"
                    redirectURI="https://chukkerbook.com/account-login"
                    responseMode="query"
                    render={(
                      renderProps //Custom Apple Sign in Button
                    ) => (
                      <Button
                        fullWidth
                        variant="outlined"
                        //onClick={() => login()}
                        onClick={renderProps.onClick}
                        startIcon={
                          <img
                            style={{ width: "24px" }}
                            src={
                              "https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg"
                            }
                            alt="Apple Logo"
                          />
                        }
                      >
                        Continue with Apple
                      </Button>
                    )}
                  />
                )}
                <Divider>or</Divider>
                <UserLogin
                  devPort={devPort}
                  newSignup={newSignUp}
                  setUserName={setUserName}
                  setAlertMessage={setAlertMessage}
                  setSnackMessage={setSnackMessage}
                  setAlertMessageSuccess={setAlertMessageSuccess}
                  setSnackMessageSuccess={setSnackMessageSuccess}
                  signupError={signupError}
                  betaMode={betaMode}
                  //setToken={setToken}
                />
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Paper>
      {!newSignUp && (
        <Stack>
          <Typography style={{ textAlign: "center" }} variant="body2">
            Not a member yet?
          </Typography>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => navigate("/account-signup")}
          >
            Sign Up
          </Button>
        </Stack>
      )}
      {newSignUp && (
        <Stack>
          <Typography style={{ textAlign: "center" }} variant="body2">
            Already have an account?
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => navigate("/account-login")}
          >
            Login
          </Button>
        </Stack>
      )}
    </Stack>
  );
}

export default SignInCard;

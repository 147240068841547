import * as React from "react";
import type { } from "@mui/x-date-pickers/themeAugmentation";
import type { } from "@mui/x-charts/themeAugmentation";
import type { } from "@mui/x-data-grid/themeAugmentation";
import type { } from "@mui/x-tree-view/themeAugmentation";
//import { alpha } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
//import AppNavbar from "../components/AnalyticsRevamp/AppNavbar";
//import Header from "../components/AnalyticsRevamp/Header";
import MainGrid from "../components/AnalyticsRevamp/MainGrid";
//import SideMenu from "../components/AnalyticsRevamp/SideMenu";
//import AppTheme from '../shared-theme/AppTheme';
import Layout from "src/components/layout/Layout";

export interface chartLegend {
  label: string;
  value: number;
}

export default function AnalyticsRevamp({
  clubId,
  devPort,
  managerMode,
  waiverNotifications,
}: {
  clubId: number;
  devPort: string;
  waiverNotifications: boolean;
  managerMode: number;
}) {
  const [weeklyGames, setWeeklyGames] = React.useState();
  const [weeklySignups, setWeeklySignups] = React.useState();

  const [weeklyLessons, setWeeklyLessons] = React.useState();
  const [weeklyLessonSignups, setWeeklyLessonSignups] = React.useState();

  const [weeklyLessonRevenue, setWeeklyLessonRevenue] = React.useState();

  const [playerStatistics, setPlayerStatistics] = React.useState<chartLegend[]>([]);
  const [playerTypeStatistics, setPlayerTypeStatistics] = React.useState<chartLegend[]>([]);
  const [totalPlayers, setTotalPlayers] = React.useState(0);


  React.useEffect(() => {
    const analytics_fetch = devPort + "get_club_analytics/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("ANALYTICS DATA");
        console.log(data);
        setWeeklyGames(data["weekly_games"]);
        setWeeklySignups(data["weekly_signups"]);
      });
  }, [devPort, clubId]);

  React.useEffect(() => {
    const analytics_fetch = devPort + "get_club_lesson_analytics/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("LESSON ANALYTICS DATA");
        console.log(data);
        setWeeklyLessons(data["weekly_lessons"]);
        setWeeklyLessonSignups(data["weekly_signups"]);
      });
  }, [devPort, clubId]);

  React.useEffect(() => {
    const analytics_fetch = devPort + "get_club_revenue_analytics/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("REVENUE ANALYTICS DATA")
        console.log(data);
        setWeeklyLessonRevenue(data['total_weekly_lesson_revenue'])
      });
  }, [devPort, clubId]);

  React.useEffect(() => {
    const analytics_fetch = devPort + "get_club_usage/" + clubId;
    console.log(analytics_fetch);
    fetch(analytics_fetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Member stats")
        console.log(data);
        if (data && data[0]) {
          const newAccountStatus = [
            // { label: "Total Players", value: parseInt(data[0][0]) },
            { label: "Active Accounts", value: data[0][3] },
            { label: "Other Accounts", value: data[0][0] - data[0][3] },
            // { label: "Pro Accounts", value: data[0][1] },
            // { label: "Amateur Accounts", value: data[0][0] - data[0][1] },
            // { label: "Chukkerbook Accounts", value: data[0][2] },
          ]

          const newPlayerType = [
            // { label: "Total Players", value: parseInt(data[0][0]) },
            // { label: "Active Accounts", value: data[0][3] },
            // { label: "Other Accounts", value: data[0][0] - data[0][3] },
            { label: "Pro Accounts", value: data[0][1] },
            { label: "Amateur Accounts", value: data[0][0] - data[0][1] },
            // { label: "Chukkerbook Accounts", value: data[0][2] },
          ]

          const totalPlayers = data[0][0]

          setPlayerStatistics(newAccountStatus);
          setPlayerTypeStatistics(newPlayerType);
          setTotalPlayers(totalPlayers);
        }
        console.log(data[0])
      });
  }, [devPort, clubId]);

  return (
    <Layout
      managerMode={managerMode}
      waiverNotifications={waiverNotifications}
      devPort={devPort}
    >
      <CssBaseline enableColorScheme />
      <Box sx={{ display: "flex" }}>
        {/*<SideMenu />
        <AppNavbar />
        {/* Main content */}
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            //backgroundColor: theme.vars
            //  ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
            //  : alpha(theme.palette.background.default, 1),
            overflow: "auto",
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: "center",
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
            }}
          >
            {/*<Header />*/}
            <MainGrid
              weeklyGames={weeklyGames}
              weeklySignups={weeklySignups}
              weeklyLessonSignups={weeklyLessonSignups}
              playerStatistics={playerStatistics}
              playerTypeStatistics={playerTypeStatistics}
              totalPlayers={totalPlayers}
              weeklyLessons={weeklyLessons}
              weeklyLessonRevenue={weeklyLessonRevenue}
              devPort={devPort}
              clubId={clubId}
            />
          </Stack>
        </Box>
      </Box>
    </Layout>
  );
}

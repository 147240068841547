import { Card, CardContent, Chip, Stack, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import Layout from "../components/layout/Layout";
import useRefresh from "../components/Refresh/refresh";
import LessonAddPricing from "../components/Lessons/LessonAddPricing";
import PricingTable from "../components/Lessons/PricingTable";
import sqlTableMapping from "../components/Lessons/sqlTableMapping";
import HorseChukker from "../components/Lessons/HorseChukker";
import LessonAddPackage from "../components/Lessons/LessonAddPackage";
import currencyFormatter from "../components/Lessons/currencyFormatter";
import DefaultMembership from "../components/Lessons/DefaultMembership";

// Adds a player's signup for a game
function LessonManagePricePage({
  clubId,
  devPort,
  waiverNotifications,
  pricingTabDefault,
}) {
  useRefresh(clubId, devPort);
  const [refreshPricing, setRefreshPricing] = useState(false);
  const [lessonPricing, setLessonPricing] = useState([]);
  const [lessonPackage, setLessonPackage] = useState([]);
  const [pricingTab, setPricingTab] = useState(pricingTabDefault);
  useEffect(() => {
    console.log(
      devPort + "lesson_pricing/" + clubId + "/" + sqlTableMapping[pricingTab]
    );
    fetch(
      devPort + "lesson_pricing/" + clubId + "/" + sqlTableMapping[pricingTab]
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (data) {
          const newLessonPricing = data.map((individualPrice) => {
            return {
              name: individualPrice[1],
              price: currencyFormatter(
                parseFloat(individualPrice[2]) / 100.0,
                individualPrice[3]
              ),
              stripe_id: individualPrice[4],
              currency: individualPrice[3],
            };
          });
          setLessonPricing(newLessonPricing);
        } else {
          setLessonPricing([]);
        }
      });
  }, [refreshPricing, devPort, clubId, pricingTab]);

  useEffect(() => {
    const packageFetch =
      devPort + "lesson_package/" + clubId + "/" + sqlTableMapping[pricingTab];
    const colorMapper = ["primary", "secondary", "success", "warning", "info"];
    console.log(packageFetch);
    fetch(packageFetch)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        let refColor = {};
        if (data) {
          const newLessonPricing = data.map((individualPrice) => {
            if (!refColor[individualPrice[2]]) {
              refColor[individualPrice[2]] =
                colorMapper[Object.keys(refColor).length % colorMapper.length];
            }
            return {
              name: individualPrice[1],
              reference: (
                <Chip
                  label={individualPrice[2]}
                  color={refColor[individualPrice[2]]}
                />
              ), // individualPrice[2],
              price: currencyFormatter(
                parseFloat(individualPrice[3]) / 100.0,
                individualPrice[5]
              ),
              stripe_id: individualPrice[6],
              currency: individualPrice[5],
              credit: individualPrice[4] ? individualPrice[4] : "∞", 
            };
          });
          console.log("PACKAGER");
          console.log(newLessonPricing);
          setLessonPackage(newLessonPricing);
        } else {
          setLessonPackage([]);
        }
      });
  }, [refreshPricing, devPort, clubId, pricingTab]);
  return (
    <Layout managerMode={-1} waiverNotifications={waiverNotifications} devPort={devPort}>
      <Tabs
        value={pricingTab}
        onChange={(event, newValue) => {
          console.log(newValue);
          setPricingTab(newValue);
        }}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab
          data-testid="membership-tab"
          value="Membership"
          label="Membership Pricing"
        />
        <Tab data-testid="lease-tab" value="Lease" label="Lease Pricing" />
        <Tab data-testid="lesson-tab" value="Lesson" label="Lesson Pricing" />
      </Tabs>
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <h1>{pricingTab} Pricing</h1>
            {pricingTab === "Lease" && (
              <HorseChukker devPort={devPort} clubId={clubId} />
            )}
            {pricingTab === "Membership" && (
              <DefaultMembership devPort={devPort} clubId={clubId} />
            )}
            <Stack spacing={3}>
              <PricingTable lessonPricing={lessonPricing} tableType="pricing" />
              <PricingTable lessonPricing={lessonPackage} tableType="package" />
              {pricingTab !== "Membership" && (
                <LessonAddPricing
                  devPort={devPort}
                  clubId={clubId}
                  priceType={pricingTab}
                  refreshPricing={refreshPricing}
                  setRefreshPricing={setRefreshPricing}
                />
              )}
              <LessonAddPackage
                devPort={devPort}
                clubId={clubId}
                priceType={pricingTab}
                prices={lessonPricing}
                refreshPricing={refreshPricing}
                setRefreshPricing={setRefreshPricing}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default LessonManagePricePage;

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { IconMapper } from "./IconMapper";
import TableCard from "./TableCard";

export default function HighlightedCard({
  title,
  iconName,
  statsDetails,
}: {
  title: string;
  iconName: string;
  statsDetails: { [key: string]: number | string };
}) {
  if (Object.keys(statsDetails).length === 0) {
    return null;
  }
  return (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <IconMapper iconName={iconName} />
        <Typography
          component="h2"
          variant="subtitle2"
          gutterBottom
          sx={{ fontWeight: "600" }}
        >
          {title}
        </Typography>
          <TableCard statsDetails={statsDetails} />
      </CardContent>
    </Card>
  );
}

function processCode(token, loginTech, devPort, setUserName, httpLocation) {
  const fetch_cmd = devPort + loginTech;
  console.log("Logging in...");
  console.log(fetch_cmd)
  const google_code = {
    code: token,
    is_using_www_route: httpLocation.match("www")
  };
  fetch(fetch_cmd, {
    method: "POST",
    body: JSON.stringify(google_code),
    credentials: "include",
    mode: "cors",
  })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
      if (data && data["data"]) {

        setUserName(data["data"]);
        //navigate("/selectmembership");
      } else {
        console.log("Invalid UserId");
      }
    });
}

export default processCode;

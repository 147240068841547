import { Card, CardContent } from "@mui/material";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "../components/layout/Layout";
import SnackError from "../components/Signups/SnackError";
import UserReset from "../components/membership/UserReset";

function PasswordResetPage({ devPort, setAlertMessageSuccess }) {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [alertMessage, setAlertMessage] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const jwtToken = searchParams.get("token");
  useEffect(() => {
    if (jwtToken) {
      console.log("Token found");
    } else {
      console.log("Redirecting...");
      navigate("/account-login");
    }
  }, [jwtToken, devPort, navigate]);

  return (
    <Layout managerMode={-2} devPort={devPort}>
      <SnackError
        alterMessage={alertMessage}
        setAlertMessage={setAlertMessage}
        snackMessage={snackMessage}
      />
      <Card>
        <CardContent>
          <Stack spacing={3} textAlign="center">
            <h1> Reset Chukkerbook Password</h1>
            <UserReset
              devPort={devPort}
              jwtToken={jwtToken}
              setAlertMessage={setAlertMessage}
              setSnackMessage={setSnackMessage}
              setAlertMessageSuccess={setAlertMessageSuccess}
            />
          </Stack>
        </CardContent>
      </Card>
    </Layout>
  );
}

export default PasswordResetPage;
